import { Avatar, Backdrop, Box, Button, CircularProgress, Fade, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, Modal, Paper, Stack, Toolbar, Typography } from '@mui/material'
import * as React from 'react'
import { Lesion } from '../../../../types'
import AddIcon from '@mui/icons-material/Add';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import { useAppSelector } from '../../../../hooks/AppReducerHooks';
import { deleteLesionDetAPI, fetchLesionsAPI } from '../../../../reducers/func/dataManagementFunc';
import { LesionLabelView } from '../views/LesionLabelView';
import { NewLesionForm } from '../forms/NewLesionForm';
import { DeletedLesionLabels } from '../views/DeletedLesionLabels';

interface ILesionDetList {
    task?: string
}


export const GILesionList: React.FC<ILesionDetList> = (props: ILesionDetList) => {
    const [openViewModal, setOpenViewModal] = React.useState(false)
    const [openCreateLesionDetModal, setOpenCreateLesionDetModal] = React.useState(false)
    const [openDeleteLesionDetModal, setOpenDeleteLesionDetModal] = React.useState(false)
    const [openNotificationModal, setOpenNotificationModal] = React.useState(false)
    const [openDeletedItemsModal, setOpenDeletedItemsModal] = React.useState(false)
    const [notificationmsg, setNotificationMsg] = React.useState('')
    const [lesions, setLesions] = React.useState<Lesion[] | null>(null)
    const [selLesion, setSelLesion] = React.useState<Lesion | null>(null)
    const { task } = props
    const userInfo = useAppSelector((state) => state.user)

    const notification = new WebSocket(
        `wss://staging-events.gutdx.com/notifications/${userInfo?.profile?.uid}/?token=${userInfo?.accessToken}`
    )
    notification.onmessage = (event) => {
        console.log("Notification: ", event.data)
        // parse notification data
        const data = JSON.parse(event.data)
        if (data.message === "detect_lesion_event" || data.message === "classify_organs_and_detect_lesions_event") {
            fetchLesionsAPI(task as string)
                .then((response) => {
                    setLesions(response.data)
                })
                .catch((error) => {
                    console.log("Error fetching lesion detections: ", error)
                })
        }

    }

    React.useEffect(() => {
        // load files list on expand
        // if (!expand) {
        fetchLesionsAPI(task as string)
            .then((response) => {
                setLesions(response.data)
            })
            .catch((error) => {
                console.log("Error fetching lesion detections: ", error)
            })
        // }
        // setExpand(!expand)
    }, [task]);

    const handleViewModalClose = () => {
        setSelLesion(null)
        setOpenViewModal(false)
    }

    const handleViewModalOpen = (lesion: Lesion) => {
        setSelLesion(lesion)
        setOpenViewModal(true)
    }

    const handleCreateLesionDetModalClose = () => {
        setOpenCreateLesionDetModal(false)
    }

    const handleCreateLesionDetModalOpen = () => {
        setOpenCreateLesionDetModal(true)
    }

    const handleDeletedItemsModalClose = () => {
        setSelLesion(null)
        setOpenDeletedItemsModal(false)
    }

    const handleDeletedItemsModalOpen = (lesion: Lesion) => {
        setSelLesion(lesion)
        setOpenDeletedItemsModal(true)
    }

    const handleCreateLesionDetSuccess = () => {
        setOpenCreateLesionDetModal(false)
        fetchLesionsAPI(task as string)
            .then((response) => {
                setLesions(response.data)
            })
            .catch((error) => {
                console.log("Error fetching smblesions: ", error)
            })

    }

    const handleDeleteLesionDetModalOpen = (smblesion: Lesion) => {
        setSelLesion(smblesion)
        setOpenDeleteLesionDetModal(true)
    }

    const handleDeleteLesionDetModalClose = () => {
        setSelLesion(null)
        setOpenDeleteLesionDetModal(false)
    }

    const handleDeleteLesionDet = () => {
        const smblesion = selLesion?.id as string
        setSelLesion(null)
        setOpenDeleteLesionDetModal(false)
        deleteLesionDetAPI(smblesion)
            .then((response: any) => {
                fetchLesionsAPI(task as string)
                    .then((response) => {
                        setLesions(response.data)
                    })
                    .catch((error) => {
                        console.log("Error fetching smblesions: ", error)
                        setNotificationMsg("Error fetching smblesions: " + error)
                        setOpenNotificationModal(true)
                    })
            })
            .catch((error) => {
                console.log("Error deleting smblesions: ", error)
                setNotificationMsg("Error deleting smblesions: " + error)
                setOpenNotificationModal(true)
            })
    }

    const handleNotificationModalClose = () => setOpenNotificationModal(false)

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%'
            }}
        >
            <Box

                sx={{
                    width: '100%',
                    height: 36,
                    backgroundColor: 'white',
                    pt: 1
                }}
            >
                <Toolbar
                    variant='dense'
                    sx={{
                        minHeight: 36,
                        borderBottom: '2px solid #212121',
                    }}
                >
                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                        <Typography variant='subtitle1'>Lesions</Typography>
                    </Box>
                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                        <Button variant='contained' startIcon={<AddIcon />}
                            onClick={handleCreateLesionDetModalOpen}
                            disableElevation
                        >
                            Detect Lesions
                        </Button>
                    </Box>
                </Toolbar>

            </Box>
            <List
                sx={{
                    width: 'calc(100% - 15px)',
                    pl: 2,
                    mt: 0.5,
                    overflow: 'auto',
                    maxHeight: '35vh'
                }}
            >
                {/* <ListItem
                secondaryAction={
                    <>
                    <Button variant="contained" sx={{ width: '180px' }} onClick={handleCreateGIOrganModalOpen}>
                        Make Classification
                    </Button>
                    </>
                }
            >
                <ListItemIcon>
                <IconButton edge='end' aria-label='showdetail' onClick={handleExpandGIOrganList}>
                {expand ? <ExpandMore sx={{ fontSize: 30 }} />:<ChevronRightIcon sx={{ fontSize: 30 }} />}
                </IconButton>
                
                </ListItemIcon>
                <ListItemAvatar>
                <DatasetIcon sx={{ fontSize: 32 }} />
                </ListItemAvatar>
                <ListItemText
                    primary="GI Organ Classification"
                />
            </ListItem> */}
                {/* <Collapse in={expand} timeout="auto" unmountOnExit> */}
                {lesions?.map((smblesion: Lesion) => (
                    <>
                        <List
                            sx={{
                                width: '90%',
                                pl: 2,
                                pr: 2,
                            }}
                        >
                            {smblesion.subtask.status === 3 || smblesion.subtask.status === 4 ? (
                                <ListItem
                                    secondaryAction={
                                        <>
                                            <IconButton edge='end' aria-label='view classification' onClick={() => handleViewModalOpen(smblesion)}>
                                                <PreviewIcon sx={{ fontSize: 26 }} />
                                            </IconButton>
                                            <IconButton edge='end' aria-label='view deleted items' onClick={() => handleDeletedItemsModalOpen(smblesion)}>
                                                <FolderDeleteIcon sx={{ fontSize: 26 }} />
                                            </IconButton>
                                            <IconButton edge='end' aria-label='remove classification' onClick={() => handleDeleteLesionDetModalOpen(smblesion)}>
                                                <DeleteIcon sx={{ fontSize: 26 }} />
                                            </IconButton>
                                        </>
                                    }
                                >
                                    <ListItemAvatar>
                                        {smblesion.dataset?.dataset_thumbnail ? (
                                            <Avatar
                                                src={smblesion.dataset?.dataset_thumbnail}
                                                sx={{
                                                    width: 48,
                                                    height: 48
                                                }}
                                                variant='square'
                                            />
                                        ) : (
                                            <CircularProgress />
                                        )}
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`SMBL-${smblesion.id}-${smblesion.dataset?.dataset_uid.split('-')[4]}`}
                                        secondary={
                                            <React.Fragment>
                                                <Typography variant='caption'>
                                                    {smblesion.subtask.status === 3 ? 'Ready' : 'Failed'}
                                                </Typography>
                                            </React.Fragment>}
                                    />
                                </ListItem>
                            ) : (
                                <ListItem
                                    secondaryAction={
                                        <>
                                            <Stack spacing={1} height='100%' justifyContent="center" alignItems="center">
                                                <Typography variant='caption'>{smblesion.subtask.status_name}</Typography>
                                                <Box sx={{ width: '90%' }}>
                                                    <LinearProgress variant='determinate' value={(smblesion.subtask.progress as number) * 100} />
                                                </Box>
                                            </Stack>
                                        </>
                                    }
                                >
                                    <ListItemAvatar>
                                        <CircularProgress />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`SMBL-${smblesion.id}-${smblesion.dataset?.dataset_uid.split('-')[4]}`}
                                        secondary={
                                            <React.Fragment>
                                                <Typography variant='caption'>
                                                    Progress: {(smblesion.subtask.progress as number) * 100}%
                                                </Typography>
                                            </React.Fragment>}
                                    />
                                </ListItem>
                            )}
                        </List>
                    </>
                ))}
                <Modal
                    aria-labelledby="view-gi-modal-title"
                    aria-describedby="view-gi-modal-description"
                    open={openViewModal}
                    onClose={handleViewModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openViewModal}>
                        <Paper
                            square={true}
                            elevation={0}
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '1080px',
                                height: '600px',
                                padding: 0
                            }}
                        >
                            <Stack spacing={0}>
                                <Toolbar
                                    variant='dense'
                                    sx={{
                                        minHeight: '38px',
                                        borderBottom: '2px solid #212121',
                                        pr: '12px !important'
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                        <Typography id="view-gi-modal-title" variant='subtitle1'>View Labels</Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                borderRadius: '4px',
                                                p: '7px',
                                                m: '4px'
                                            }}
                                            onClick={handleViewModalClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Toolbar>
                                <Box
                                    sx={{
                                        m: 2,
                                        pl: 3,
                                        pr: 3
                                    }}
                                >
                                    <LesionLabelView lesion={selLesion} />
                                </Box>

                            </Stack>
                        </Paper>
                    </Fade>
                </Modal>
                {/* </Collapse> */}
                <Modal
                    aria-labelledby="view-ds-modal-title"
                    aria-describedby="view-ds-modal-description"
                    open={openCreateLesionDetModal}
                    onClose={handleCreateLesionDetModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openCreateLesionDetModal}>
                        <Paper
                            square={true}
                            elevation={0}
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '400px',
                                height: '312px',
                                padding: 0
                            }}
                        >
                            <Stack spacing={0}>
                                <Toolbar
                                    variant='dense'
                                    sx={{
                                        minHeight: '38px',
                                        borderBottom: '2px solid #212121',
                                        pr: '12px !important'
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                        <Typography id="view-ds-modal-title" variant='subtitle1'>Create Dataset</Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                borderRadius: '4px',
                                                p: '7px',
                                                m: '4px'
                                            }}
                                            onClick={handleCreateLesionDetModalClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Toolbar>
                                <Box
                                    sx={{
                                        m: 2,
                                        pl: 1,
                                        pr: 1
                                    }}
                                >
                                    <NewLesionForm task={task} onSuccess={handleCreateLesionDetSuccess} />
                                </Box>

                            </Stack>
                        </Paper>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="delete-gi-modal-title"
                    aria-describedby="delete-gi-modal-description"
                    open={openDeleteLesionDetModal}
                    onClose={handleDeleteLesionDetModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openDeleteLesionDetModal}>
                        <Paper
                            square={true}
                            elevation={0}
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '400px',
                                height: '312px',
                                padding: 0
                            }}
                        >
                            <Stack spacing={0}>
                                <Toolbar
                                    variant='dense'
                                    sx={{
                                        minHeight: '38px',
                                        borderBottom: '2px solid #212121'
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                        <Typography id="delete-ds-modal-title" variant='subtitle1'>Delete lesion detection</Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                borderRadius: '4px',
                                                m: '4px',
                                                p: '7px'
                                            }}
                                            onClick={handleDeleteLesionDetModalClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Toolbar>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        height: '200px',
                                        p: 1,
                                        overflow: 'auto'
                                    }}
                                >
                                    <Typography variant='body1'>Are you sure you want to delete the lesion detection?</Typography>
                                </Box>
                                <Box
                                    justifyContent='flex-end'
                                    sx={{
                                        display: 'flex',
                                        minHeight: '38px',
                                        pt: 1,
                                        pl: 2,
                                        pr: 2,
                                        borderTop: '1px solid #212121'
                                    }}
                                >
                                    <Button
                                        variant='contained'
                                        onClick={handleDeleteLesionDetModalClose}
                                        sx={{
                                            ml: 1,
                                            mr: 1
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant='contained'
                                        onClick={handleDeleteLesionDet}
                                        sx={{
                                            ml: 1,
                                            mr: 1
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            </Stack>
                        </Paper>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="notification-modal-title"
                    aria-describedby="notification-modal-description"
                    open={openNotificationModal}
                    onClose={handleNotificationModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openNotificationModal}>
                        <Paper
                            square={true}
                            elevation={0}
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '400px',
                                height: '312px',
                                padding: 0
                            }}
                        >
                            <Stack spacing={0}>
                                <Toolbar
                                    variant='dense'
                                    sx={{
                                        minHeight: '38px',
                                        borderBottom: '2px solid #212121'
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                        <Typography id="addpatient-modal-title" variant='subtitle1'>Add New Patient</Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                borderRadius: '4px',
                                                m: '4px',
                                                p: '7px'
                                            }}
                                            onClick={handleNotificationModalClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Toolbar>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        height: '200px',
                                        p: 1,
                                        overflow: 'auto'
                                    }}
                                >
                                    <Typography variant='body1'>{notificationmsg}</Typography>
                                </Box>
                                <Box
                                    justifyContent='flex-end'
                                    sx={{
                                        display: 'flex',
                                        minHeight: '38px',
                                        pt: 1,
                                        pl: 2,
                                        pr: 2,
                                        borderTop: '1px solid #212121'
                                    }}
                                >
                                    <Button
                                        variant='contained'
                                        onClick={handleNotificationModalClose}
                                    >
                                        Close
                                    </Button>
                                </Box>
                            </Stack>
                        </Paper>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="view-deleteditems-modal-title"
                    aria-describedby="view-deleteditems-modal-description"
                    open={openDeletedItemsModal}
                    onClose={handleDeletedItemsModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openDeletedItemsModal}>
                        <Paper
                            square={true}
                            elevation={0}
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '620px',
                                height: '620px',
                                padding: 0
                            }}
                        >
                            <Stack spacing={0}>
                                <Toolbar
                                    variant='dense'
                                    sx={{
                                        minHeight: '38px',
                                        borderBottom: '2px solid #212121',
                                        pr: '12px !important'
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                        <Typography id="view-deleteditems-modal-title" variant='subtitle1'>View Deleted Items</Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                borderRadius: '4px',
                                                p: '7px',
                                                m: '4px'
                                            }}
                                            onClick={handleDeletedItemsModalClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Toolbar>
                                <Box
                                    sx={{
                                        m: 2,
                                        pl: 3,
                                        pr: 3
                                    }}
                                >
                                    <DeletedLesionLabels ds={selLesion} />
                                </Box>

                            </Stack>
                        </Paper>
                    </Fade>
                </Modal>
            </List>
        </Box>
    )
}