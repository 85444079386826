import { Avatar, Backdrop, Box, Button, CircularProgress, Collapse, Fade, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material'
import * as React from 'react'
import { Dataset, GIOrgan } from '../../../../types'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DatasetIcon from '@mui/icons-material/Dataset';
import AddIcon from '@mui/icons-material/Add';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import { DatasetView } from '../views/DatasetView';
import { GIOrganLabelView } from '../views/GIOrganLabelView';
import { deleteGIOCAPI, fetchGIOrgansAPI } from '../../../../reducers/func/dataManagementFunc';
import { NewGIOrganForm } from '../forms/NewGIOrganForm';
import { useAppSelector } from '../../../../hooks/AppReducerHooks';
import { DeletedGIOrganLabels } from '../views/DeletedGIOrganLabels';

interface IGIOrganList {
    //giorgans?: GIOrgan[] | null
    task?: string
}


export const GIOrganList: React.FC<IGIOrganList> = (props: IGIOrganList) => {
    // const [expand, setExpand] = React.useState(false)
    const [openViewModal, setOpenViewModal] = React.useState(false)
    const [openCreateGIOrganModal, setOpenCreateGIOrganModal] = React.useState(false)
    const [openDeleteGIOrganModal, setOpenDeleteGIOrganModal] = React.useState(false)
    const [openNotificationModal, setOpenNotificationModal] = React.useState(false)
    const [openDeletedItemsModal, setOpenDeletedItemsModal] = React.useState(false)
    const [notificationmsg, setNotificationMsg] = React.useState('')
    const [giorgans, setGIOrgans] = React.useState<GIOrgan[] | null>(null)
    const [selGI, setSelGI] = React.useState<GIOrgan | null>(null)
    const { task } = props
    const userInfo = useAppSelector((state) => state.user)

    const notification = new WebSocket(
        `wss://staging-events.gutdx.com/notifications/${userInfo?.profile?.uid}/?token=${userInfo?.accessToken}`
    )
    notification.onmessage = (event) => {
        console.log("Notification: ", event.data)
        // parse notification data
        const data = JSON.parse(event.data)
        if (data.message === "classify_organs_event" || data.message === "classify_organs_and_detect_lesions_event") {
            fetchGIOrgansAPI(task as string)
                .then((response) => {
                    setGIOrgans(response.data)
                })
                .catch((error) => {
                    console.log("Error fetching classifications: ", error)
                })
        }

    }

    React.useEffect(() => {
        // load files list on expand
        // if (!expand) {
        fetchGIOrgansAPI(task as string)
            .then((response) => {
                setGIOrgans(response.data)
            })
            .catch((error) => {
                console.log("Error fetching classifications: ", error)
            })
        // }
        // setExpand(!expand)
    }, [task]);

    const handleViewModalClose = () => {
        setSelGI(null)
        setOpenViewModal(false)
    }

    const handleViewModalOpen = (gi: GIOrgan) => {
        setSelGI(gi)
        setOpenViewModal(true)
    }

    const handleCreateGIOrganModalClose = () => {
        setOpenCreateGIOrganModal(false)
    }

    const handleCreateGIOrganModalOpen = () => {
        setOpenCreateGIOrganModal(true)
    }

    const handleDeletedItemsModalClose = () => {
        setSelGI(null)
        setOpenDeletedItemsModal(false)
    }

    const handleDeletedItemsModalOpen = (gi: GIOrgan) => {
        setSelGI(gi)
        setOpenDeletedItemsModal(true)
    }

    const handleCreateGIOrganSuccess = () => {
        setOpenCreateGIOrganModal(false)
        fetchGIOrgansAPI(task as string)
            .then((response) => {
                setGIOrgans(response.data)
            })
            .catch((error) => {
                console.log("Error fetching giorgans: ", error)
            })

    }

    const handleDeleteGIOrganModalOpen = (gi: GIOrgan) => {
        setSelGI(gi)
        setOpenDeleteGIOrganModal(true)
    }

    const handleDeleteGIOrganModalClose = () => {
        setSelGI(null)
        setOpenDeleteGIOrganModal(false)
    }

    const handleDeleteGIOC = () => {
        const gioc = selGI?.id as string
        setSelGI(null)
        setOpenDeleteGIOrganModal(false)
        deleteGIOCAPI(gioc)
            .then((response: any) => {
                fetchGIOrgansAPI(task as string)
                    .then((response) => {
                        setGIOrgans(response.data)
                    })
                    .catch((error) => {
                        console.log("Error fetching datasets: ", error)
                        setNotificationMsg("Error fetching datasets: " + error)
                        setOpenNotificationModal(true)
                    })
            })
            .catch((error) => {
                console.log("Error deleting GI Organ Classification: ", error)
                setNotificationMsg("Error deleting GI Organ Classification: " + error)
                setOpenNotificationModal(true)
            })
    }

    const handleNotificationModalClose = () => setOpenNotificationModal(false)

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%'
            }}
        >
            <Box

                sx={{
                    width: '100%',
                    height: 36,
                    backgroundColor: 'white',
                    pt: 1
                }}
            >
                <Toolbar
                    variant='dense'
                    sx={{
                        minHeight: 36,
                        borderBottom: '2px solid #212121',
                    }}
                >
                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                        <Typography variant='subtitle1'>Organs</Typography>
                    </Box>
                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                        <Button variant='contained' startIcon={<AddIcon />}
                            onClick={handleCreateGIOrganModalOpen}
                            disableElevation
                        >
                            Classify GI Organs
                        </Button>
                    </Box>
                </Toolbar>

            </Box>
            <List
                sx={{
                    width: 'calc(100% - 15px)',
                    pl: 2,
                    mt: 0.5,
                    overflow: 'auto',
                    maxHeight: '35vh'
                }}
            >
                {/* <ListItem
                secondaryAction={
                    <>
                    <Button variant="contained" sx={{ width: '180px' }} onClick={handleCreateGIOrganModalOpen}>
                        Make Classification
                    </Button>
                    </>
                }
            >
                <ListItemIcon>
                <IconButton edge='end' aria-label='showdetail' onClick={handleExpandGIOrganList}>
                {expand ? <ExpandMore sx={{ fontSize: 30 }} />:<ChevronRightIcon sx={{ fontSize: 30 }} />}
                </IconButton>
                
                </ListItemIcon>
                <ListItemAvatar>
                <DatasetIcon sx={{ fontSize: 32 }} />
                </ListItemAvatar>
                <ListItemText
                    primary="GI Organ Classification"
                />
            </ListItem> */}
                {/* <Collapse in={expand} timeout="auto" unmountOnExit> */}
                {giorgans?.map((giorgan: GIOrgan) => (
                    <>
                        <List
                            sx={{
                                width: '90%',
                                pl: 2,
                                pr: 2,
                            }}
                        >
                            {giorgan.subtask.status === 3 || giorgan.subtask.status === 4 ? (
                                <ListItem
                                    secondaryAction={
                                        <>
                                            <IconButton edge='end' aria-label='view classification' onClick={() => handleViewModalOpen(giorgan)}>
                                                <PreviewIcon sx={{ fontSize: 26 }} />
                                            </IconButton>
                                            <IconButton edge='end' aria-label='view deleted items' onClick={() => handleDeletedItemsModalOpen(giorgan)}>
                                                <FolderDeleteIcon sx={{ fontSize: 26 }} />
                                            </IconButton>
                                            <IconButton edge='end' aria-label='remove classification' onClick={() => handleDeleteGIOrganModalOpen(giorgan)}>
                                                <DeleteIcon sx={{ fontSize: 26 }} />
                                            </IconButton>
                                        </>
                                    }
                                >
                                    <ListItemAvatar>
                                        {giorgan.dataset?.dataset_thumbnail ? (
                                            <Avatar
                                                src={giorgan.dataset?.dataset_thumbnail}
                                                sx={{
                                                    width: 48,
                                                    height: 48
                                                }}
                                                variant='square'
                                            />
                                        ) : (
                                            <CircularProgress />
                                        )}
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`GIOC-${giorgan.id}-${giorgan.dataset?.dataset_uid.split('-')[4]}`}
                                        secondary={
                                            <React.Fragment>
                                                <Typography variant='caption'>
                                                    {giorgan.subtask.status === 3 ? 'Ready' : 'Failed'}
                                                </Typography>
                                            </React.Fragment>}
                                    />
                                </ListItem>
                            ) : (
                                <ListItem
                                    secondaryAction={
                                        <>
                                            <Stack spacing={1} height='100%' justifyContent="center" alignItems="center">
                                                <Typography variant='caption'>{giorgan.subtask.status_name}</Typography>
                                                <Box sx={{ width: '90%' }}>
                                                    <LinearProgress variant='determinate' value={(giorgan.subtask.progress as number) * 100} />
                                                </Box>
                                            </Stack>
                                        </>
                                    }
                                >
                                    <ListItemAvatar>
                                        <CircularProgress />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`GIOC-${giorgan.id}-${giorgan.dataset?.dataset_uid.split('-')[4]}`}
                                        secondary={
                                            <React.Fragment>
                                                <Typography variant='caption'>
                                                    Progress: {(giorgan.subtask.progress as number) * 100}%
                                                </Typography>
                                            </React.Fragment>}
                                    />
                                </ListItem>
                            )}
                        </List>
                    </>
                ))}
                <Modal
                    aria-labelledby="view-gi-modal-title"
                    aria-describedby="view-gi-modal-description"
                    open={openViewModal}
                    onClose={handleViewModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openViewModal}>
                        <Paper
                            square={true}
                            elevation={0}
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '1080px',
                                height: '600px',
                                padding: 0
                            }}
                        >
                            <Stack spacing={0}>
                                <Toolbar
                                    variant='dense'
                                    sx={{
                                        minHeight: '38px',
                                        borderBottom: '2px solid #212121',
                                        pr: '12px !important'
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                        <Typography id="view-gi-modal-title" variant='subtitle1'>View Labels</Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                borderRadius: '4px',
                                                p: '7px',
                                                m: '4px'
                                            }}
                                            onClick={handleViewModalClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Toolbar>
                                <Box
                                    sx={{
                                        m: 2,
                                        pl: 3,
                                        pr: 3
                                    }}
                                >
                                    <GIOrganLabelView gi={selGI} />
                                </Box>

                            </Stack>
                        </Paper>
                    </Fade>
                </Modal>
                {/* </Collapse> */}
                <Modal
                    aria-labelledby="view-ds-modal-title"
                    aria-describedby="view-ds-modal-description"
                    open={openCreateGIOrganModal}
                    onClose={handleCreateGIOrganModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openCreateGIOrganModal}>
                        <Paper
                            square={true}
                            elevation={0}
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '500px',
                                height: '312px',
                                padding: 0
                            }}
                        >
                            <Stack spacing={0}>
                                <Toolbar
                                    variant='dense'
                                    sx={{
                                        minHeight: '38px',
                                        borderBottom: '2px solid #212121',
                                        pr: '12px !important'
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                        <Typography id="view-ds-modal-title" variant='subtitle1'>Create Dataset</Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                borderRadius: '4px',
                                                p: '7px',
                                                m: '4px'
                                            }}
                                            onClick={handleCreateGIOrganModalClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Toolbar>
                                <Box
                                    sx={{
                                        m: 2,
                                        pl: 1,
                                        pr: 1
                                    }}
                                >
                                    <NewGIOrganForm task={task} onSuccess={handleCreateGIOrganSuccess} />
                                </Box>

                            </Stack>
                        </Paper>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="delete-gi-modal-title"
                    aria-describedby="delete-gi-modal-description"
                    open={openDeleteGIOrganModal}
                    onClose={handleDeleteGIOrganModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openDeleteGIOrganModal}>
                        <Paper
                            square={true}
                            elevation={0}
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '400px',
                                height: '312px',
                                padding: 0
                            }}
                        >
                            <Stack spacing={0}>
                                <Toolbar
                                    variant='dense'
                                    sx={{
                                        minHeight: '38px',
                                        borderBottom: '2px solid #212121'
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                        <Typography id="delete-ds-modal-title" variant='subtitle1'>Delete Dataset</Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                borderRadius: '4px',
                                                m: '4px',
                                                p: '7px'
                                            }}
                                            onClick={handleDeleteGIOrganModalClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Toolbar>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        height: '200px',
                                        p: 1,
                                        overflow: 'auto'
                                    }}
                                >
                                    <Typography variant='body1'>Are you sure you want to delete the classification?</Typography>
                                </Box>
                                <Box
                                    justifyContent='flex-end'
                                    sx={{
                                        display: 'flex',
                                        minHeight: '38px',
                                        pt: 1,
                                        pl: 2,
                                        pr: 2,
                                        borderTop: '1px solid #212121'
                                    }}
                                >
                                    <Button
                                        variant='contained'
                                        onClick={handleDeleteGIOrganModalClose}
                                        sx={{
                                            ml: 1,
                                            mr: 1
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant='contained'
                                        onClick={handleDeleteGIOC}
                                        sx={{
                                            ml: 1,
                                            mr: 1
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            </Stack>
                        </Paper>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="notification-modal-title"
                    aria-describedby="notification-modal-description"
                    open={openNotificationModal}
                    onClose={handleNotificationModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openNotificationModal}>
                        <Paper
                            square={true}
                            elevation={0}
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '400px',
                                height: '312px',
                                padding: 0
                            }}
                        >
                            <Stack spacing={0}>
                                <Toolbar
                                    variant='dense'
                                    sx={{
                                        minHeight: '38px',
                                        borderBottom: '2px solid #212121'
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                        <Typography id="addpatient-modal-title" variant='subtitle1'>Add New Patient</Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                borderRadius: '4px',
                                                m: '4px',
                                                p: '7px'
                                            }}
                                            onClick={handleNotificationModalClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Toolbar>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        height: '200px',
                                        p: 1,
                                        overflow: 'auto'
                                    }}
                                >
                                    <Typography variant='body1'>{notificationmsg}</Typography>
                                </Box>
                                <Box
                                    justifyContent='flex-end'
                                    sx={{
                                        display: 'flex',
                                        minHeight: '38px',
                                        pt: 1,
                                        pl: 2,
                                        pr: 2,
                                        borderTop: '1px solid #212121'
                                    }}
                                >
                                    <Button
                                        variant='contained'
                                        onClick={handleNotificationModalClose}
                                    >
                                        Close
                                    </Button>
                                </Box>
                            </Stack>
                        </Paper>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="view-deleteditems-modal-title"
                    aria-describedby="view-deleteditems-modal-description"
                    open={openDeletedItemsModal}
                    onClose={handleDeletedItemsModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openDeletedItemsModal}>
                        <Paper
                            square={true}
                            elevation={0}
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '620px',
                                height: '620px',
                                padding: 0
                            }}
                        >
                            <Stack spacing={0}>
                                <Toolbar
                                    variant='dense'
                                    sx={{
                                        minHeight: '38px',
                                        borderBottom: '2px solid #212121',
                                        pr: '12px !important'
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                        <Typography id="view-deleteditems-modal-title" variant='subtitle1'>View Deleted Items</Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                borderRadius: '4px',
                                                p: '7px',
                                                m: '4px'
                                            }}
                                            onClick={handleDeletedItemsModalClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Toolbar>
                                <Box
                                    sx={{
                                        m: 2,
                                        pl: 3,
                                        pr: 3
                                    }}
                                >
                                    <DeletedGIOrganLabels ds={selGI} />
                                </Box>

                            </Stack>
                        </Paper>
                    </Fade>
                </Modal>
            </List>
        </Box>
    )
}