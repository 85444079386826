import * as React from 'react'
import { useForm } from 'react-hook-form'
import { ImageDataframe } from '../../../../types'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { applyImageDataframeTSAPI } from '../../../../reducers/func/dataManagementFunc'
import { InputGroup, InputNumber } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import '../../../../styles/inputnumber.css'

interface IEditImageDataframe {
    dataframe: ImageDataframe
    onEdit: (dataframe: ImageDataframe) => void
}

// a function to covert integer to two-digit string with leading zero
function intToTwoDigitString(n: number): string {
    return n.toString().padStart(2, '0')
}

export const EditImageDataframe: React.FC<IEditImageDataframe> = (props: IEditImageDataframe) => {
    const [edited, setEdited] = React.useState<boolean>(false)
    const { dataframe, onEdit } = props
    const form = useForm()

    const handleHHMinus = () => {
        var hh = parseInt(dataframe.hms.split(':')[0]) - 1
        var mm = parseInt(dataframe.hms.split(':')[1])
        var ss = parseInt(dataframe.hms.split(':')[2])
        setEdited(true)
        const newdataframe: ImageDataframe = {
            ...dataframe,
            ts: hh * 3600 + mm * 60 + ss,
            hms: `${intToTwoDigitString(hh)}:${intToTwoDigitString(mm)}:${intToTwoDigitString(ss)}`,
            edited: true
        }
        onEdit(newdataframe)

    };
    const handleHHPlus = () => {
        var hh = parseInt(dataframe.hms.split(':')[0]) + 1
        var mm = parseInt(dataframe.hms.split(':')[1])
        var ss = parseInt(dataframe.hms.split(':')[2])
        setEdited(true)
        const newdataframe: ImageDataframe = {
            ...dataframe,
            ts: hh * 3600 + mm * 60 + ss,
            hms: `${intToTwoDigitString(hh)}:${intToTwoDigitString(mm)}:${intToTwoDigitString(ss)}`,
            edited: true
        }
        onEdit(newdataframe)
    };
    const handleDataframeHourChange = (value: number) => {
        const hh = parseInt(value as any)
        const mm = parseInt(dataframe.hms.split(':')[1])
        const ss = parseInt(dataframe.hms.split(':')[2])
        const ts = hh * 3600 + mm * 60 + ss
        // setSelLabel(label)
        setEdited(true)
        const newdataframe: ImageDataframe = {
            ...dataframe,
            ts: ts,
            hms: `${intToTwoDigitString(hh)}:${intToTwoDigitString(mm)}:${intToTwoDigitString(ss)}`,
            edited: true
        }
        onEdit(newdataframe)
    }

    const handleDataframeMinuteChange = (value: number) => {
        const hh = parseInt(dataframe.hms.split(':')[0])
        const mm = parseInt(value as any)
        const ss = parseInt(dataframe.hms.split(':')[2])
        const ts = hh * 3600 + mm * 60 + ss
        // setSelLabel(label)
        setEdited(true)
        const newdataframe: ImageDataframe = {
            ...dataframe,
            ts: ts,
            hms: `${intToTwoDigitString(hh)}:${intToTwoDigitString(mm)}:${intToTwoDigitString(ss)}`,
            edited: true
        }
        onEdit(newdataframe)
    }

    const handleDataframeSecondChange = (value: number) => {
        const hh = parseInt(dataframe.hms.split(':')[0])
        const mm = parseInt(dataframe.hms.split(':')[1])
        const ss = parseInt(value as any)
        const ts = hh * 3600 + mm * 60 + ss
        // setSelLabel(label)
        setEdited(true)
        const newdataframe: ImageDataframe = {
            ...dataframe,
            ts: ts,
            hms: `${intToTwoDigitString(hh)}:${intToTwoDigitString(mm)}:${intToTwoDigitString(ss)}`,
            edited: true
        }
        onEdit(newdataframe)
    }

    const handleApplyChange = () => {
        applyImageDataframeTSAPI(dataframe.id as string, dataframe.ts)
            .then((response) => {
                setEdited(false)
                console.log("Apply change success: ", response)
                onEdit({ ...dataframe, edited: false })
            })
            .catch((error) => {
                console.log("Error applying change: ", error)
            })
    }

    React.useEffect(() => {
        if (dataframe !== null && dataframe !== undefined) {

            if (dataframe.edited !== undefined && dataframe.edited !== null) {
                setEdited(dataframe.edited)
            } else {
                setEdited(false)
            }

        }
    }, [dataframe])

    return (
        <form>
            <Grid container spacing={1} alignItems='flex-start' justifyContent='flex-start'>
                <Grid item xs={6}>
                    <Grid container direction='column' spacing={1} alignItems='flex-start' justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Stack direction='row' spacing={2} sx={{ m: 1 }} alignItems='flex-start' justifyContent='flex-start'>
                                <Box>
                                    <Typography variant='body2'>Id: {(dataframe !== undefined && dataframe !== null) ? dataframe.id : ''} </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={2} sx={{ m: 1, width: '80%' }} alignItems='flex-start' justifyContent='flex-start'>
                                <InputGroup>
                                    <InputNumber prefix="H" postfix="hours" size={'sm'} value={dataframe !== undefined ? parseInt(dataframe.hms.split(':')[0]) : 0} onChange={(v, e) => handleDataframeHourChange(v as number)} />
                                </InputGroup>
                                <InputGroup>
                                    <InputNumber prefix="M" postfix="minutes" size={'sm'} value={dataframe !== undefined ? parseInt(dataframe.hms.split(':')[1]) : 0} onChange={(v, e) => handleDataframeMinuteChange(v as number)} />
                                </InputGroup>
                                <InputGroup>
                                    <InputNumber prefix="S" postfix="seconds" size={'sm'} value={dataframe !== undefined ? parseInt(dataframe.hms.split(':')[2]) : 0} onChange={(v, e) => handleDataframeSecondChange(v as number)} />
                                </InputGroup>




                            </Stack>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={6}>
                    <Stack direction='column' spacing={1} sx={{ m: 1 }} alignItems='flex-start' justifyContent='flex-start'>
                        <Button
                            variant='contained'
                            disabled={!edited}
                            onClick={handleApplyChange}
                        >
                            Apply change
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </form>
    )
}