import * as React from 'react'
import { Dataset, ImageDataframe, ImageDataframeViewset, instanceofImageDataframe } from '../../../../types';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, IconButton, Stack, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { ImageDataframeList } from '../lists/ImageDataframeList';
import { fetchDeletedImageDataAPI, restoreImageframesAPI } from '../../../../reducers/func/dataManagementFunc';
import { de } from 'date-fns/locale';

interface IDeletedDataframesProps {
    ds: Dataset | null
}

export const DeletedDataframes: React.FC<IDeletedDataframesProps> = (props: IDeletedDataframesProps) => {
    const [deletedimagedataviewset, setDeletedImageDataViewset] = React.useState<ImageDataframeViewset | null>(null)
    const [multiSelection, setMultiSelection] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const { ds } = props
    const handleFrameSelected = (item: ImageDataframe) => {
        if (item !== undefined && instanceofImageDataframe(item)) {
            // find pointer location
            if (deletedimagedataviewset !== null) {
                const pointer = deletedimagedataviewset.data?.findIndex((element: ImageDataframe) => element.id === item.id)
                const selitems = deletedimagedataviewset.selitems
                if (multiSelection) {
                    if (deletedimagedataviewset.selitems !== null) {
                        const index = selitems?.findIndex((element: ImageDataframe) => element.id === item.id)
                        if (index === -1) {
                            selitems?.push(item)
                        } else if (index !== undefined) {
                            selitems?.splice(index, 1)
                        }

                    }
                }
                setDeletedImageDataViewset({
                    ...deletedimagedataviewset,
                    selitem: item,
                    selitems: selitems,
                    pointer: pointer
                })

            }
        }


    }

    const handleViewsetChanged = (viewset: ImageDataframeViewset) => {
        console.log("Viewset changed: ", deletedimagedataviewset)
        setDeletedImageDataViewset(viewset as ImageDataframeViewset)
    }

    const handleFetchData = (page: number) => {
        setLoading(true)
        fetchDeletedImageDataAPI(ds?.id as string, page)
            .then((response) => {
                if (page > 1 && deletedimagedataviewset !== null) {
                    const data = deletedimagedataviewset?.data?.concat(response.data.results) as any
                    handleViewsetChanged({
                        ...deletedimagedataviewset,
                        data: data,
                        page: page,
                        count: response.data.count,
                    })
                } else {
                    handleViewsetChanged({
                        data: response.data.results,
                        totalpages: response.data.total_pages,
                        page: 1,
                        count: response.data.count,
                        selitem: response.data.results[0],
                        selitems: [],
                        pointer: 0
                    })
                }
                setLoading(false)
            })
            .catch((error) => {
                console.log("Error fetching deleted dataframes: ", error)
                setLoading(false)
            })
    }

    const handleLoadMore = () => {
        if (deletedimagedataviewset !== null) {
            if (deletedimagedataviewset.page as number + 1 <= (deletedimagedataviewset.totalpages as number)) {
                handleFetchData(deletedimagedataviewset.page as number + 1)
            }
        }
    }

    const toggleMultiSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMultiSelection(event.target.checked);
        // Add current selitem to selitems
        if (deletedimagedataviewset !== null) {
            if (event.target.checked) {
                setDeletedImageDataViewset({
                    ...deletedimagedataviewset,
                    selitems: [deletedimagedataviewset.selitem as ImageDataframe]
                })
            } else {
                setDeletedImageDataViewset({
                    ...deletedimagedataviewset,
                    selitems: []
                })
            }
        }
    }

    const handleRestoreSelectedItems = () => {
        // Create a list of selected item ids
        const selitems: number[] = []
        deletedimagedataviewset?.selitems?.forEach((item: ImageDataframe) => {
            selitems.push(item.id as unknown as number)
        })
        restoreImageframesAPI(selitems)
            .then((response) => {
                console.log("Restore selected items: ", response)
                handleFetchData(deletedimagedataviewset?.page as number)
            })
            .catch((error) => {
                console.log("Error restoring selected items: ", error)
            })
    }

    React.useEffect(() => {
        if (ds !== null) {
            handleFetchData(1)
        }
    }, [ds])

    return (
        <>
            <Stack spacing={0}>
                <Box
                    sx={{
                        width: '100%',
                        height: 520,
                    }}
                >
                    <ImageDataframeList
                        datatype='dataframe'
                        viewset={deletedimagedataviewset as ImageDataframeViewset}
                        showLabel={true}
                        onItemSelected={handleFrameSelected}
                    />
                </Box>
                <Box>
                    <Grid container>
                        <Grid item xs={6}>
                            <Stack direction='row' spacing={1} alignItems='left' justifyContent='left'>
                                <FormControlLabel
                                    label={multiSelection ? deletedimagedataviewset?.selitems?.length + ' selected' : 'Enable Multi-Select'}
                                    control={<Checkbox checked={multiSelection} onChange={toggleMultiSelection} />}
                                />



                                <IconButton
                                    aria-label="restore"
                                    onClick={handleRestoreSelectedItems}
                                    disabled={deletedimagedataviewset?.selitem === null || deletedimagedataviewset?.selitem === undefined}>
                                    <Tooltip title='Restore selected item'>
                                        <RestoreFromTrashIcon />
                                    </Tooltip>
                                </IconButton>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction='row' spacing={1} alignItems='right' justifyContent='right'>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    size='small'
                                    endIcon={loading ? <CircularProgress size={16} /> : <MoreHorizIcon />}
                                    onClick={handleLoadMore}
                                    disabled={loading || deletedimagedataviewset?.page === deletedimagedataviewset?.totalpages}
                                    sx={{
                                        width: 120,
                                        mr: 2,
                                        pr: 0
                                    }}>
                                    {loading ? 'Loading' : 'Load more'}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </>
    )
}