import { Avatar, Backdrop, Box, Button, Collapse, Fade, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material'
import * as React from 'react'
import { Dataset } from '../../../../types'
import AddIcon from '@mui/icons-material/Add';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import { DatasetView } from '../views/DatasetView';
import { NewDatasetForm } from '../forms/NewDatasetForm';
import { deleteDatasetAPI, fetchDatasetAPI, fetchDatasetsAPI } from '../../../../reducers/func/dataManagementFunc';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppSelector } from '../../../../hooks/AppReducerHooks';
import { DeletedDataframes } from '../views/DeletedDataframes';

interface IDatasetList {
    // datasets?: Dataset[] | null
    task?: string
}


export const DatasetList: React.FC<IDatasetList> = (props: IDatasetList) => {
    const userInfo = useAppSelector((state) => state.user)
    // const [expand, setExpand] = React.useState(false)
    const [openViewDSModal, setOpenViewDSModal] = React.useState(false)
    const [openCreateDSModal, setOpenCreateDSModal] = React.useState(false)
    const [openDeleteDSModal, setOpenDeleteDSModal] = React.useState(false)
    const [openDeletedItemsModal, setOpenDeletedItemsModal] = React.useState(false)
    const [datasets, setDatasets] = React.useState<Dataset[] | null>(null)
    const [selDS, setSelDS] = React.useState<Dataset | null>(null)
    const [openNotificationModal, setOpenNotificationModal] = React.useState(false)
    const [notificationmsg, setNotificationMsg] = React.useState('')
    const { task } = props
    const notification = new WebSocket(
        `wss://staging-events.gutdx.com/notifications/${userInfo?.profile?.uid}/?token=${userInfo?.accessToken}`
    )
    notification.onmessage = (event) => {
        console.log("Notification: ", event.data)
        // parse notification data
        const data = JSON.parse(event.data)
        if (data.message === "extract_data_event" || data.message === "import_timestamp_data_event") {
            fetchDatasetsAPI(task as string)
                .then((response) => {
                    setDatasets(response.data)
                })
                .catch((error) => {
                    console.log("Error fetching datasets: ", error)
                })
        }

    }
    // const handleExpandDatasetList = () => {
    React.useEffect(() => {
        // load files list on expand
        // if (!expand) {
        fetchDatasetsAPI(task as string)
            .then((response) => {
                setDatasets(response.data)
            })
            .catch((error) => {
                console.log("Error fetching datasets: ", error)
            })
        // }
        // setExpand(!expand)
    }, [task]);

    const handleViewDSModalClose = () => {
        setSelDS(null)
        setOpenViewDSModal(false)
    }

    const handleViewDSModalOpen = (ds: Dataset) => {
        setSelDS(ds)
        setOpenViewDSModal(true)
    }

    const handleCreateDSModalClose = () => {
        setOpenCreateDSModal(false)
    }

    const handleCreateDSModalOpen = () => {
        setOpenCreateDSModal(true)
    }

    const handleDeletedItemsModalClose = () => {
        setSelDS(null)
        setOpenDeletedItemsModal(false)
    }

    const handleDeletedItemsModalOpen = (ds: Dataset) => {
        setSelDS(ds)
        setOpenDeletedItemsModal(true)
    }

    const handleCreateDSSuccess = () => {
        setOpenCreateDSModal(false)
        fetchDatasetsAPI(task as string)
            .then((response) => {
                setDatasets(response.data)
            })
            .catch((error) => {
                console.log("Error fetching datasets: ", error)
            })

    }

    const handleUpdateDS = (ds: string) => {
        fetchDatasetAPI(ds as string)
            .then((response: any) => {
                setSelDS(response.data)
                const updated_datasets: Dataset[] | undefined = datasets?.map((dataset: Dataset) => {
                    if (dataset.id === ds) {
                        return {
                            ...response.data,
                        }
                    }
                    return dataset
                })
                if (updated_datasets) setDatasets(updated_datasets)
            })
            .catch((error) => {
                console.log("Error fetching datasets: ", error)
            })
    }

    const handleDeleteDSModalClose = () => {
        setSelDS(null)
        setOpenDeleteDSModal(false)
    }

    const handleDeleteDSModalOpen = (ds: Dataset) => {
        setSelDS(ds)
        setOpenDeleteDSModal(true)
    }

    const handleDeleteDS = () => {
        const ds = selDS?.id as string
        setSelDS(null)
        setOpenDeleteDSModal(false)
        deleteDatasetAPI(ds)
            .then((response: any) => {
                fetchDatasetsAPI(task as string)
                    .then((response) => {
                        setDatasets(response.data)
                    })
                    .catch((error) => {
                        console.log("Error fetching datasets: ", error)
                        setNotificationMsg("Error fetching datasets: " + error)
                        setOpenNotificationModal(true)
                    })
            })
            .catch((error) => {
                console.log("Error deleting datasets: ", error)
                setNotificationMsg("Error deleting datasets: " + error)
                setOpenNotificationModal(true)
            })
    }

    const handleNotificationModalClose = () => setOpenNotificationModal(false)

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%'
            }}
        >
            <Box

                sx={{
                    width: '100%',
                    height: 36,
                    backgroundColor: 'white',
                    pt: 1
                }}
            >
                <Toolbar
                    variant='dense'
                    sx={{
                        minHeight: 36,
                        borderBottom: '2px solid #212121',
                    }}
                >
                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                        <Typography variant='subtitle1'>Datasets</Typography>
                    </Box>
                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                        <Button variant='contained' startIcon={<AddIcon />}
                            onClick={handleCreateDSModalOpen}
                            disableElevation
                        >
                            Create Dataset
                        </Button>
                    </Box>
                </Toolbar>

            </Box>
            <List
                sx={{
                    width: 'calc(100% - 15px)',
                    pl: 2,
                    mt: 0.5,
                    overflow: 'auto',
                    maxHeight: '35vh'
                }}
            >
                {/* <ListItem
                    secondaryAction={
                        <>
                            <Button variant="contained" sx={{ width: '180px' }} onClick={handleCreateDSModalOpen}>
                                Create Dataset
                            </Button>
                        </>
                    }
                >
                    <ListItemIcon>
                        <IconButton edge='end' aria-label='showdetail' onClick={handleExpandDatasetList}>
                            {expand ? <ExpandMore sx={{ fontSize: 30 }} /> : <ChevronRightIcon sx={{ fontSize: 30 }} />}
                        </IconButton>

                    </ListItemIcon>
                    <ListItemAvatar>
                        <DatasetIcon sx={{ fontSize: 32 }} />
                    </ListItemAvatar>
                    <ListItemText
                        primary="Datasets"
                    />
                </ListItem> */}
                {/* <Collapse in={expand} timeout="auto" unmountOnExit> */}
                {datasets?.map((ds: Dataset) => (
                    <>
                        <List
                            sx={{
                                width: '90%',
                                pl: 2,
                                pr: 2,
                            }}
                        >
                            {ds.subtask.status === 3 || ds.subtask.status === 4 ? (
                                <ListItem
                                    secondaryAction={
                                        <>
                                            <IconButton edge='end' aria-label='preview dataset' onClick={() => handleViewDSModalOpen(ds)}>
                                                <PreviewIcon sx={{ fontSize: 26 }} />
                                            </IconButton>
                                            <IconButton edge='end' aria-label='view deleted items' onClick={() => handleDeletedItemsModalOpen(ds)}>
                                                <FolderDeleteIcon sx={{ fontSize: 26 }} />
                                            </IconButton>
                                            <IconButton edge='end' aria-label='remove dataset' onClick={() => handleDeleteDSModalOpen(ds)}>
                                                <DeleteIcon sx={{ fontSize: 26 }} />
                                            </IconButton>
                                        </>
                                    }
                                >
                                    <ListItemAvatar>
                                        {ds.dataset_thumbnail ? (
                                            <Avatar
                                                src={ds.dataset_thumbnail}
                                                sx={{
                                                    width: 48,
                                                    height: 48
                                                }}
                                                variant='square'
                                            />
                                        ) : (
                                            <CircularProgress />
                                        )}
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={ds.dataset_uid}
                                        secondary={
                                            <React.Fragment>
                                                <Typography variant='caption'>
                                                    Total samples: {ds.total_samples}
                                                </Typography>
                                            </React.Fragment>}
                                    />
                                </ListItem>
                            ) : (
                                <ListItem
                                    secondaryAction={
                                        <>
                                            <Stack spacing={1} height='100%' justifyContent="center" alignItems="center">
                                                <Typography variant='caption'>{ds.subtask.status_name}</Typography>
                                                <Box sx={{ width: '90%' }}>
                                                    <LinearProgress variant='determinate' value={(ds.subtask.progress as number) * 100} />
                                                </Box>
                                            </Stack>
                                        </>
                                    }
                                >
                                    <ListItemAvatar>
                                        <CircularProgress />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={ds.dataset_uid}
                                        secondary={
                                            <React.Fragment>
                                                <Typography variant='caption'>
                                                    Total samples: {ds.total_samples}
                                                </Typography>
                                            </React.Fragment>}
                                    />
                                </ListItem>
                            )}
                        </List>

                    </>
                ))}
                <Modal
                    aria-labelledby="view-ds-modal-title"
                    aria-describedby="view-ds-modal-description"
                    open={openViewDSModal}
                    onClose={handleViewDSModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openViewDSModal}>
                        <Paper
                            square={true}
                            elevation={0}
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '1080px',
                                height: '620px',
                                padding: 0
                            }}
                        >
                            <Stack spacing={0}>
                                <Toolbar
                                    variant='dense'
                                    sx={{
                                        minHeight: '38px',
                                        borderBottom: '2px solid #212121',
                                        pr: '12px !important'
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                        <Typography id="view-ds-modal-title" variant='subtitle1'>View Dataset</Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                borderRadius: '4px',
                                                p: '7px',
                                                m: '4px'
                                            }}
                                            onClick={handleViewDSModalClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Toolbar>
                                <Box
                                    sx={{
                                        m: 2,
                                        pl: 3,
                                        pr: 3
                                    }}
                                >
                                    <DatasetView ds={selDS} />
                                </Box>

                            </Stack>
                        </Paper>
                    </Fade>
                </Modal>
                {/* </Collapse> */}
                <Modal
                    aria-labelledby="view-ds-modal-title"
                    aria-describedby="view-ds-modal-description"
                    open={openCreateDSModal}
                    onClose={handleCreateDSModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openCreateDSModal}>
                        <Paper
                            square={true}
                            elevation={0}
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '480px',
                                height: '312px',
                                padding: 0
                            }}
                        >
                            <Stack spacing={0}>
                                <Toolbar
                                    variant='dense'
                                    sx={{
                                        minHeight: '38px',
                                        borderBottom: '2px solid #212121',
                                        pr: '12px !important'
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                        <Typography id="view-ds-modal-title" variant='subtitle1'>Create Dataset</Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                borderRadius: '4px',
                                                p: '7px',
                                                m: '4px'
                                            }}
                                            onClick={handleCreateDSModalClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Toolbar>
                                <Box
                                    sx={{
                                        m: 2,
                                        pl: 1,
                                        pr: 1
                                    }}
                                >
                                    <NewDatasetForm task={task} onSuccess={handleCreateDSSuccess} />
                                </Box>

                            </Stack>
                        </Paper>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="delete-ds-modal-title"
                    aria-describedby="delete-ds-modal-description"
                    open={openDeleteDSModal}
                    onClose={handleDeleteDSModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openDeleteDSModal}>
                        <Paper
                            square={true}
                            elevation={0}
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '400px',
                                height: '312px',
                                padding: 0
                            }}
                        >
                            <Stack spacing={0}>
                                <Toolbar
                                    variant='dense'
                                    sx={{
                                        minHeight: '38px',
                                        borderBottom: '2px solid #212121'
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                        <Typography id="delete-ds-modal-title" variant='subtitle1'>Delete Dataset</Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                borderRadius: '4px',
                                                m: '4px',
                                                p: '7px'
                                            }}
                                            onClick={handleDeleteDSModalClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Toolbar>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        height: '200px',
                                        p: 1,
                                        overflow: 'auto'
                                    }}
                                >
                                    <Typography variant='body1'>Are you sure you want to delete dataset {selDS?.dataset_uid}?</Typography>
                                </Box>
                                <Box
                                    justifyContent='flex-end'
                                    sx={{
                                        display: 'flex',
                                        minHeight: '38px',
                                        pt: 1,
                                        pl: 2,
                                        pr: 2,
                                        borderTop: '1px solid #212121'
                                    }}
                                >
                                    <Button
                                        variant='contained'
                                        onClick={handleDeleteDSModalClose}
                                        sx={{
                                            ml: 1,
                                            mr: 1
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant='contained'
                                        onClick={handleDeleteDS}
                                        sx={{
                                            ml: 1,
                                            mr: 1
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            </Stack>
                        </Paper>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="notification-modal-title"
                    aria-describedby="notification-modal-description"
                    open={openNotificationModal}
                    onClose={handleNotificationModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openNotificationModal}>
                        <Paper
                            square={true}
                            elevation={0}
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '400px',
                                height: '312px',
                                padding: 0
                            }}
                        >
                            <Stack spacing={0}>
                                <Toolbar
                                    variant='dense'
                                    sx={{
                                        minHeight: '38px',
                                        borderBottom: '2px solid #212121'
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                        <Typography id="addpatient-modal-title" variant='subtitle1'>Add New Patient</Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                borderRadius: '4px',
                                                m: '4px',
                                                p: '7px'
                                            }}
                                            onClick={handleNotificationModalClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Toolbar>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        height: '200px',
                                        p: 1,
                                        overflow: 'auto'
                                    }}
                                >
                                    <Typography variant='body1'>{notificationmsg}</Typography>
                                </Box>
                                <Box
                                    justifyContent='flex-end'
                                    sx={{
                                        display: 'flex',
                                        minHeight: '38px',
                                        pt: 1,
                                        pl: 2,
                                        pr: 2,
                                        borderTop: '1px solid #212121'
                                    }}
                                >
                                    <Button
                                        variant='contained'
                                        onClick={handleNotificationModalClose}
                                    >
                                        Close
                                    </Button>
                                </Box>
                            </Stack>
                        </Paper>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="view-deleteditems-modal-title"
                    aria-describedby="view-deleteditems-modal-description"
                    open={openDeletedItemsModal}
                    onClose={handleDeletedItemsModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openDeletedItemsModal}>
                        <Paper
                            square={true}
                            elevation={0}
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '620px',
                                height: '620px',
                                padding: 0
                            }}
                        >
                            <Stack spacing={0}>
                                <Toolbar
                                    variant='dense'
                                    sx={{
                                        minHeight: '38px',
                                        borderBottom: '2px solid #212121',
                                        pr: '12px !important'
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                        <Typography id="view-deleteditems-modal-title" variant='subtitle1'>View Deleted Items</Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                borderRadius: '4px',
                                                p: '7px',
                                                m: '4px'
                                            }}
                                            onClick={handleDeletedItemsModalClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Toolbar>
                                <Box
                                    sx={{
                                        m: 2,
                                        pl: 3,
                                        pr: 3
                                    }}
                                >
                                    <DeletedDataframes ds={selDS} />
                                </Box>

                            </Stack>
                        </Paper>
                    </Fade>
                </Modal>
            </List>
        </Box>
    )
}