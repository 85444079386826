import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { HealthCareInstitution } from '../../../../types'
import { addPhysicianAPI, fetchInstitutionAPI } from '../../../../reducers/func/dataManagementFunc'
import { Autocomplete, Box, Button, Stack, TextField } from '@mui/material'
import { useFieldState } from '@mui/x-date-pickers/internals/hooks/useField/useFieldState'

interface INewPhysician {
    onSaveResult: (success: boolean, msg: string) => void
}

// A function that returns random password of fixed length
const generatePassword = (length: number) => {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    let password = ""
    for (let i = 0; i < length; i++) {
        password += charset.charAt(Math.floor(Math.random() * charset.length))
    }
    return password
}

export const AddPhysicianForm: React.FC<INewPhysician> = (props: INewPhysician) => {
    const form = useForm()
    const [institutions, setInstitutions] = React.useState<HealthCareInstitution[]>([])
    const [autoGenPass, setAutoGenPass] = React.useState<string>(generatePassword(8))
    const [assignedInstitution, setAssignedInstitution] = React.useState<HealthCareInstitution | null>(null)
    const onNewPhysicianSubmitted = async (data: any) => {
        const postdata = {
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            phone: data.phone,
            institution: assignedInstitution?.id,
            password: data.password
        }
        try {
            await addPhysicianAPI(postdata)
            props.onSaveResult(true, "")
        } catch (e) {
            props.onSaveResult(false, "Error saving data to the backend.\n" + e)
        }
    }
    React.useEffect(() => {
        if (institutions.length === 0) {
            fetchInstitutionAPI()
                .then((response) => {
                    setInstitutions(response.data)
                })
                .catch((error) => {
                    console.log("Error fetching institutions: ", error)
                })
        }

    }, [])

    return (
        <>
            <form>
                <Box
                    sx={{
                        display: 'flex',
                        height: '330px',
                        p: 1,
                        overflow: 'auto'
                    }}
                >
                    <Stack spacing={1}>
                        <Controller
                            name="firstname"
                            control={form.control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'First name is required'
                                }
                            }}
                            render={({ field, fieldState, formState }) =>
                                <TextField
                                    {...field}
                                    variant="standard"
                                    label="First Name"
                                    color="secondary"
                                    error={fieldState.error ? true : false}
                                    helperText={fieldState.error && fieldState.error.message}
                                    sx={{ width: 200 }} />}
                        />
                        <Controller
                            name="lastname"
                            control={form.control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Last name is required'
                                }
                            }}
                            render={({ field, fieldState, formState }) =>
                                <TextField
                                    {...field}
                                    variant="standard"
                                    label="Last Name"
                                    color="secondary"
                                    error={fieldState.error ? true : false}
                                    helperText={fieldState.error && fieldState.error.message}
                                    sx={{ width: 200 }} />}
                        />
                        <Controller
                            name="email"
                            control={form.control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Email is required'
                                }
                            }}
                            render={({ field, fieldState, formState }) =>
                                <TextField
                                    {...field}
                                    variant="standard"
                                    label="Email"
                                    color="secondary"
                                    error={fieldState.error ? true : false}
                                    helperText={fieldState.error && fieldState.error.message}
                                    sx={{ width: 200 }} />}
                        />
                        <Controller
                            name="phone"
                            control={form.control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Phone number is required'
                                }
                            }}
                            render={({ field, fieldState, formState }) =>
                                <TextField
                                    {...field}
                                    variant="standard"
                                    label="Phone Number"
                                    color="secondary"
                                    error={fieldState.error ? true : false}
                                    helperText={fieldState.error && fieldState.error.message}
                                    sx={{ width: 200 }} />}
                        />
                        <Controller
                            name="institution"
                            control={form.control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Healthcare Institute is required."
                                }
                            }}
                            render={({ field, fieldState, formState }) =>
                                <Autocomplete
                                    {...field}
                                    disablePortal
                                    options={institutions}
                                    sx={{ width: 200 }}
                                    onChange={(event: any, newValue: any | null) => {
                                        field.onChange(newValue.name)
                                        setAssignedInstitution(newValue)
                                    }}
                                    getOptionLabel={(option: any) => option.name}

                                    renderInput={(params: any) =>
                                        <TextField {...params}
                                            label="Healthcare Institute (required)"
                                            variant="standard"
                                            color="secondary"
                                            error={fieldState.error ? true : false}
                                            helperText={fieldState.error && fieldState.error.message}

                                        />}

                                />

                            }
                        />
                        <Controller
                            name="password"
                            control={form.control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Password is required'
                                }
                            }}
                            defaultValue={autoGenPass}
                            render={({ field, fieldState, formState }) =>
                                <TextField
                                    {...field}
                                    variant="standard"
                                    label="Password"
                                    color="secondary"
                                    error={fieldState.error ? true : false}
                                    helperText={fieldState.error && fieldState.error.message}
                                    sx={{ width: 200 }} />}
                        />
                    </Stack>
                </Box>
                <Box
                    justifyContent='flex-end'
                    sx={{
                        display: 'flex',
                        minHeight: '38px',
                        pt: 1,
                        pl: 2,
                        pr: 2,
                        borderTop: '1px solid #212121'
                    }}
                >
                    <Button
                        variant='contained'
                        onClick={form.handleSubmit(onNewPhysicianSubmitted)}
                    >
                        Save New Physician
                    </Button>
                </Box>
            </form>
        </>
    )
}