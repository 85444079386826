import { Autocomplete, Backdrop, Box, Button, CircularProgress, Fade, Grid, IconButton, LinearProgress, Modal, Paper, Stack, TextField, Toolbar, Typography } from '@mui/material'
import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close';
import { DataSrcToggler } from '../togglers/DataSrcToggler';
import { Profile, Patient, HealthCareInstitution, Task } from '../../../../types';
import { fetchProfilesAPI } from '../../../../reducers/func/userFunc';
import { addTaskAPI, fetchInstitutionAPI, fetchPatientsAPI, fetchPhysiciansAPI, uploadAPI } from '../../../../reducers/func/dataManagementFunc';
import { AddInstitutionForm } from './AddInstitutionForm';
import { AddPatientForm } from './AddPatientForm';
import { AddPhysicianForm } from './AddPhysicianForm';

const generatetaskname = () => {
    const now = new Date()
    const dt = `${now.getFullYear()}-${('0' + (now.getMonth() + 1)).slice(-2)}-${('0' + now.getDate()).slice(-2)}-${('0' + now.getHours()).slice(-2)}${('0' + now.getMinutes()).slice(-2)}${('0' + now.getSeconds()).slice(-2)}`
    return `CAPSULE-${dt}`
}

interface INewTask {
    onClose: () => void
    onSaveComplete: (reload: boolean) => void
}

export const AddCapsuleEndoscopyTask: React.FC<INewTask> = (props: INewTask) => {
    const form = useForm()
    const [taskname, setTaskName] = React.useState(generatetaskname())
    const [taskdatasrctype, setTaskDataSrcType] = React.useState<string | null>(null)
    const [taskdatasrcfiles, setTaskDataSrcFiles] = React.useState<File[] | null>(null)
    const [taskinstitution, setTaskInstitution] = React.useState<HealthCareInstitution | null>(null)
    const [institutions, setInstitutions] = React.useState<HealthCareInstitution[]>([])
    const [taskpatient, setTaskPatient] = React.useState<Patient | null>(null)
    const [patients, setPatients] = React.useState<Patient[]>([])
    const [taskphysician, setTaskPhysician] = React.useState<Profile | null>(null)
    const [physicians, setPhysicians] = React.useState<Profile[]>([])

    const [openNotificationModal, setOpenNotificationModal] = React.useState(false)
    const [openAddInstitutionModal, setOpenAddInstitutionModal] = React.useState(false)
    const [openAddPatientModal, setOpenAddPatientModal] = React.useState(false)
    const [openAddPhysicianModal, setOpenAddPhysicianModal] = React.useState(false)
    const [notificationmsg, setNotificationMsg] = React.useState('')
    const [showsavingbackdrop, setSavingBackdrop] = React.useState(false)
    const [showsavingtaskmsg, setShowSavingTaskMsg] = React.useState(false)
    const [showuploadingmsg, setShowUploadingMsg] = React.useState(false)
    const [showsavetaskcompletemsg, setShowSaveTaskCompleteMsg] = React.useState(false)
    const [showsavetaskerrormsg, setShowSaveTaskErrorMsg] = React.useState(false)
    const [currentuploadfile, setCurrentUploadFile] = React.useState<File | null>(null)
    const [uploadprogress, setUploadProgress] = React.useState(0)
    const { onClose, onSaveComplete } = props

    const handleSelectDataSrc = (srctype: string, srcfiles: File[] | null) => {
        setTaskDataSrcType(srctype)
        setTaskDataSrcFiles(srcfiles)
    }

    const handleAddInstitutionModalOpen = () => setOpenAddInstitutionModal(true)
    const handleAddInstitutionModalClose = () => setOpenAddInstitutionModal(false)

    const handleAddPatientModalOpen = () => setOpenAddPatientModal(true)
    const handleAddPatientModalClose = () => setOpenAddPatientModal(false)

    const handleAddPhysicianModalOpen = () => setOpenAddPhysicianModal(true)
    const handleAddPhysicianModalClose = () => setOpenAddPhysicianModal(false)

    const handleNotificationModalOpen = () => setOpenNotificationModal(true)
    const handleNotificationModalClose = () => setOpenNotificationModal(false)

    const handleSaveNewPatient = () => {

    }

    const handleSaveNewPhysician = () => {

    }

    const handleClose = () => {
        onClose()
    }

    const handleSelectTaskInstitution = (institution: HealthCareInstitution) => {
        setTaskInstitution(institution)
        fetchPatientsAPI(institution.id as string)
            .then((response) => setPatients(response.data))
            .catch((error) => {
                console.log("Error fetching patients: ", error)
            })
    }

    const handleOnSaveInstitutionResultReturned = (success: boolean, msg: string) => {
        setOpenAddInstitutionModal(false)
        if (success) {
            fetchInstitutionAPI()
                .then((response) => {
                    setInstitutions(response.data)
                })
                .catch((error) => {
                    console.log("Error fetching institutions: ", error)
                })
        } else {
            setNotificationMsg(msg)
            setOpenNotificationModal(true)
        }
    }

    const handleOnSavePatientResultReturned = (success: boolean, msg: string) => {
        setOpenAddPatientModal(false)
        if (success && taskinstitution) {
            fetchPatientsAPI(taskinstitution.id as string)
                .then((response) => {
                    setPatients(response.data)
                })
                .catch((error) => {
                    console.log("Error fetching patients: ", error)
                })
        } else {
            setNotificationMsg(msg)
            setOpenNotificationModal(true)

        }
    }

    const handleOnSavePhysicianResultReturned = (success: boolean, msg: string) => {
        setOpenAddPhysicianModal(false)
        if (success) {
            fetchPhysiciansAPI()
                .then((response) => {
                    setPhysicians(response.data)
                })
                .catch((error) => {
                    console.log("Error fetching physicians: ", error)
                })
        } else {
            setNotificationMsg(msg)
            setOpenNotificationModal(true)
        }
    }

    const handleSaveTaskSubmitted = (data: any) => {
        const postdata: Task = {
            task_name: data.taskname,
            patient: taskpatient,
            physician: taskphysician?.uid,
            product: 0
        }
        setSavingBackdrop(true)
        setShowSavingTaskMsg(true)
        addTaskAPI(postdata)
            .then(async (response) => {
                setShowSavingTaskMsg(false)

                const taskId = response.data.task as number
                // taskdatasrcfiles?.forEach((f: File) => {

                //     await handleUploadingFile(taskId, f)

                // })
                if (taskdatasrcfiles) {
                    for (const f of taskdatasrcfiles) {
                        await handleUploadingFile(taskId, f)
                    }
                }

                setShowSaveTaskCompleteMsg(true)


            })
            .catch((error) => {
                console.log("Error saving new task: ", error)
            })

    }

    const handleUploadingFile = async (task: number, f: File) => {
        try {
            setShowUploadingMsg(true)
            setUploadProgress(0)
            setCurrentUploadFile(f)
            // set chunk size to 10 MB
            const chunkSize = 10000000
            const num_chunks = Math.ceil(f.size / chunkSize)
            console.log("Number of chunks: ", num_chunks)
            let start = 0
            let end = Math.min(start + chunkSize, f.size)
            for (let i = 0; i < num_chunks; i++) {
                let last: boolean = (i === (num_chunks - 1))
                let chunk: Blob = f.slice(start, end)
                console.log("File name: ", f.name)
                console.log("Uploading chunk: ", i)
                console.log("Chunk size: ", chunk.size)
                console.log("Start: ", start)
                console.log("End: ", end)
                console.log("Last chunk: ", last)
                start = end
                end = Math.min(start + chunkSize, f.size)

                if (last) {
                    console.log("last chunk to be submitted.")
                }

                await uploadAPI(task, chunk, f.name, last, (event: any) => {
                    setUploadProgress(Math.round(((event.loaded / event.total + i) * (chunkSize)) * 100 / f.size))
                })
            }
            setCurrentUploadFile(null)
            setShowUploadingMsg(false)
        } catch (e) {
            console.log("Error uploading file: ", e)
            setCurrentUploadFile(null)
            setShowUploadingMsg(false)
        }
    }

    const handleClosingSavingTaskSuccessModal = () => {
        setSavingBackdrop(false)
        onSaveComplete(true)
    }

    const handleClosingSavingTaskFailModal = () => {
        setSavingBackdrop(false)

    }

    React.useEffect(() => {
        if (institutions.length === 0) {
            fetchInstitutionAPI()
                .then((response) => {
                    setInstitutions(response.data)
                })
                .catch((error) => {
                    console.log("Error fetching institutions: ", error)
                })
        }
        if (physicians.length === 0) {
            fetchPhysiciansAPI()
                .then((response) => {
                    setPhysicians(response.data)
                })
                .catch((error) => {
                    console.log("Error fetching physicians: ", error)
                })
        }
        const default_taskname = generatetaskname()
        setTaskName(default_taskname)
    }, [institutions.length, physicians.length])

    return (
        <>

            <Paper
                square={true}
                elevation={0}
                sx={{
                    width: '100%',
                    height: '100%',
                    padding: 0
                }}
            >

                <Stack spacing={0}>
                    <Toolbar
                        variant='dense'
                        sx={{
                            minHeight: '38px',
                            borderBottom: '2px solid #212121'
                        }}
                    >
                        <Box sx={{ flexGrow: 1, display: 'flex' }}>
                            <Typography variant='subtitle1'>New Task</Typography>
                        </Box>
                        <Box sx={{ flexGrow: 0, display: 'flex' }}>
                            <IconButton
                                size='small'
                                sx={{
                                    borderRadius: '4px',
                                    m: '4px',
                                    p: '7px'
                                }}
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Toolbar>
                    <Stack spacing={2} sx={{ m: 1 }}>
                        <form>

                            <Controller
                                name="taskname"
                                control={form.control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Task name is required"
                                    }
                                }}
                                defaultValue={taskname}
                                render={({ field, fieldState, formState }) =>
                                    <TextField
                                        {...field}
                                        variant="standard"
                                        label="Task name"
                                        color="primary"
                                        sx={{ width: "100%" }}
                                        onChange={(event) => {
                                            field.onChange(event.target.value as string)
                                            setTaskName(event.target.value as string)
                                        }}
                                        error={fieldState.error ? true : false}
                                        helperText={fieldState.error && fieldState.error.message}
                                    />}
                            />

                            <Grid container direction='row' spacing={1}>
                                <Grid item xs={7}>
                                    <Controller
                                        name="taskdata"
                                        control={form.control}
                                        rules={{

                                            validate: (value: any) => {
                                                if (taskdatasrcfiles && taskdatasrcfiles.length > 0) {
                                                    return true
                                                }

                                                return false
                                            }
                                        }}
                                        render={({ field, fieldState, formState }) =>
                                            <TextField
                                                {...field}
                                                value={taskdatasrctype && taskdatasrcfiles ? `${taskdatasrctype}: ${taskdatasrcfiles.length} files selected` : ''}
                                                variant="standard"
                                                label="Data Source Location"
                                                color="primary"
                                                sx={{ width: "100%" }}
                                                error={fieldState.error ? true : false}
                                                helperText={fieldState.error ?
                                                    (fieldState.error?.type !== 'validate' ? fieldState.error?.message : 'At least one file is needed.') : ''}
                                            />}
                                    />
                                </Grid>
                                <Grid item container xs={5} alignItems="center">
                                    <DataSrcToggler onDataSrcSelected={handleSelectDataSrc} />

                                    {/* <input type="file" id="yourdata" 
                            ref={this.fileRef} 
                            style={{display: 'none'}} 
                            onChange={this.handleFileSelected} /> */}
                                </Grid>

                            </Grid>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs={7}>
                                    <Controller
                                        name="institute"
                                        control={form.control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Healthcare Institute is required."
                                            }
                                        }}
                                        render={({ field, fieldState, formState }) =>
                                            <Autocomplete
                                                {...field}
                                                disablePortal
                                                options={institutions}
                                                sx={{ width: 200 }}
                                                value={taskinstitution}
                                                onChange={(event: any, newValue: any | null) => {
                                                    field.onChange(newValue.name)
                                                    handleSelectTaskInstitution(newValue);
                                                }}
                                                getOptionLabel={(option: any) => option.name}

                                                renderInput={(params: any) =>
                                                    <TextField {...params}
                                                        label="Healthcare Institute"
                                                        variant="standard"
                                                        color="secondary"
                                                        error={fieldState.error ? true : false}
                                                        helperText={fieldState.error && fieldState.error.message}
                                                    />}
                                            //onChange={(_event, data) => field.onChange(data?.code ?? '')}
                                            />}
                                    />
                                </Grid>
                                <Grid item container xs={5} alignItems="center">
                                    <Button
                                        variant='contained'
                                        onClick={handleAddInstitutionModalOpen}

                                    >
                                        Add Institution
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs={7}>
                                    <Controller
                                        name="patient"
                                        control={form.control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Patient is required."
                                            }
                                        }}
                                        render={({ field, fieldState, formState }) =>
                                            <Autocomplete
                                                {...field}
                                                disablePortal
                                                options={patients}
                                                sx={{ width: 200 }}
                                                value={taskpatient}
                                                onChange={(event: any, newValue: any | null) => {
                                                    field.onChange(newValue.refno)
                                                    setTaskPatient(newValue);
                                                }}
                                                getOptionLabel={(option: any) => option.refno}

                                                renderInput={(params: any) =>
                                                    <TextField {...params}
                                                        label="Patient"
                                                        variant="standard"
                                                        color="secondary"
                                                        error={fieldState.error ? true : false}
                                                        helperText={fieldState.error && fieldState.error.message}
                                                    />}
                                            //onChange={(_event, data) => field.onChange(data?.code ?? '')}
                                            />}
                                    />
                                </Grid>
                                <Grid item container xs={5} alignItems="center">
                                    <Button
                                        variant='contained'
                                        onClick={handleAddPatientModalOpen}

                                    >
                                        Add Patient
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs={7}>
                                    <Controller
                                        name="physician"
                                        control={form.control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Physician is required."
                                            }
                                        }}
                                        render={({ field, fieldState, formState }) =>
                                            <Autocomplete
                                                {...field}
                                                disablePortal
                                                options={physicians}
                                                sx={{ width: 200 }}
                                                value={taskphysician}
                                                onChange={(event: any, newValue: any | null) => {
                                                    field.onChange(newValue.user.name)
                                                    setTaskPhysician(newValue);
                                                }}
                                                getOptionLabel={(option: any) => option.user.name}

                                                renderInput={(params: any) =>
                                                    <TextField {...params}
                                                        label="Physician"
                                                        variant="standard"
                                                        color="secondary"
                                                        error={fieldState.error ? true : false}
                                                        helperText={fieldState.error && fieldState.error.message}
                                                    />}
                                            //onChange={(_event, data) => field.onChange(data?.code ?? '')}
                                            />}
                                    />
                                </Grid>
                                <Grid item container xs={5} alignItems="center">
                                    <Button
                                        variant='contained'
                                        onClick={handleAddPhysicianModalOpen}
                                    >
                                        Add Physician
                                    </Button>
                                </Grid>
                            </Grid>

                            {/* <Grid container direction="row" spacing={1}>
                    <Grid item xs={7}>
                        <Controller
                            name="physician"
                            control={form.control}
                            rules={{ 
                                required: {
                                    value: true,
                                    message: "Physician is required."
                                }
                            }}
                            render={({ field, fieldState, formState }) => 
                            <Autocomplete
                                {...field}
                                disablePortal
                                options={physicians}
                                sx={{ width: 200 }}
                                value={taskphysician}
                                onChange={(event: any, newValue: any | null) => {
                                    field.onChange(newValue.code)
                                    setTaskPhysician(newValue);
                                }}
                                getOptionLabel={(option: any) => option.user?.name}
                        
                                renderInput={(params: any) => 
                                    <TextField {...params} 
                                        label="Physician" 
                                        variant="standard" 
                                        color="secondary"
                                    />}
                                //onChange={(_event, data) => field.onChange(data?.code ?? '')}
                                />}
                        /> 
                    </Grid>
                    <Grid item container xs={5} alignItems="center">
                        <Button
                            variant='contained'
                            onClick={handleAddPhysicianModalOpen}
                            
                        >
                            Add Physician
                        </Button>
                    </Grid>
                </Grid> */}
                            <Box
                                justifyContent='flex-end'
                                sx={{
                                    display: 'flex',
                                    minHeight: '38px',
                                    mt: 2,
                                    pt: 1,
                                    pl: 2,
                                    pr: 2,
                                    borderTop: '1px solid #212121'
                                }}
                            >
                                <Button
                                    variant='contained'
                                    onClick={form.handleSubmit(handleSaveTaskSubmitted)}
                                >
                                    Save New Task
                                </Button>
                            </Box>
                        </form>
                    </Stack>
                </Stack>

            </Paper>
            <Backdrop
                open={showsavingbackdrop}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <Paper
                    square={true}
                    elevation={0}
                    sx={{
                        width: 300,
                        height: 200,
                        padding: 0
                    }}
                >
                    {showsavingtaskmsg && (
                        <Stack spacing={1} height='100%' justifyContent="center" alignItems="center">
                            <Typography variant='h6'>Saving New Task</Typography>
                            <CircularProgress color="inherit" />
                        </Stack>
                    )}
                    {showuploadingmsg && (
                        <Stack spacing={1} height='100%' justifyContent="center" alignItems="center">
                            <Box sx={{ width: '90%' }}>
                                <LinearProgress variant='determinate' value={uploadprogress} />
                            </Box>
                            <Typography variant='caption'>Uploading {currentuploadfile?.name}</Typography>

                        </Stack>
                    )}
                    {showsavetaskcompletemsg && (
                        <Stack spacing={0}>
                            <Toolbar
                                variant='dense'
                                sx={{
                                    minHeight: '38px',
                                    pr: '8px !important'
                                }}
                            >
                                <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                    <Typography variant='subtitle1'>Result</Typography>
                                </Box>
                                <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'flex-end' }}>
                                    <IconButton
                                        size='small'
                                        sx={{
                                            borderRadius: '4px',
                                            m: '4px',
                                            p: '7px'
                                        }}
                                        onClick={handleClosingSavingTaskSuccessModal}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                            <Stack spacing={1}
                                height='100%'
                                justifyContent="center"
                                alignItems="flex-start"
                                sx={{
                                    m: 2
                                }}>
                                <Typography variant='body1'>Task saved successfully</Typography>
                            </Stack>
                        </Stack>
                    )}

                    {showsavetaskerrormsg && (
                        <Stack spacing={0}>
                            <Toolbar
                                variant='dense'
                                sx={{
                                    minHeight: '38px',
                                    pr: '8px !important'
                                }}
                            >
                                <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                    <Typography variant='subtitle1'>Error</Typography>
                                </Box>
                                <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'flex-end' }}>
                                    <IconButton
                                        size='small'
                                        sx={{
                                            borderRadius: '4px',
                                            m: '4px',
                                            p: '7px'
                                        }}
                                        onClick={handleClosingSavingTaskFailModal}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                            <Stack spacing={1}
                                height='100%'
                                justifyContent="center"
                                alignItems="flex-start"
                                sx={{
                                    m: 2
                                }}>
                                <Typography variant='body1'>Failed saving task</Typography>
                            </Stack>
                        </Stack>
                    )}



                </Paper>
            </Backdrop>
            <Modal
                aria-labelledby="addinstitution-modal-title"
                aria-describedby="addinstitution-modal-description"
                open={openAddInstitutionModal}
                onClose={handleAddInstitutionModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openAddInstitutionModal}>
                    <Paper
                        square={true}
                        elevation={0}
                        sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '400px',
                            height: '312px',
                            padding: 0
                        }}
                    >
                        <Stack spacing={0}>
                            <Toolbar
                                variant='dense'
                                sx={{
                                    minHeight: '38px',
                                    borderBottom: '2px solid #212121'
                                }}
                            >
                                <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                    <Typography id="addinstitution-modal-title" variant='subtitle1'>Add New Institution</Typography>
                                </Box>
                                <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                    <IconButton
                                        size='small'
                                        sx={{
                                            borderRadius: '4px',
                                            m: '4px',
                                            p: '7px'
                                        }}
                                        onClick={handleAddInstitutionModalClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                            <AddInstitutionForm onSaveResult={handleOnSaveInstitutionResultReturned} />
                        </Stack>
                    </Paper>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="addpatient-modal-title"
                aria-describedby="addpatient-modal-description"
                open={openAddPatientModal}
                onClose={handleAddPatientModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openAddPatientModal}>
                    <Paper
                        square={true}
                        elevation={0}
                        sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '400px',
                            height: '462px',
                            padding: 0
                        }}
                    >
                        <Stack spacing={0}>
                            <Toolbar
                                variant='dense'
                                sx={{
                                    minHeight: '38px',
                                    borderBottom: '2px solid #212121'
                                }}
                            >
                                <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                    <Typography id="addpatient-modal-title" variant='subtitle1'>Add New Patient</Typography>
                                </Box>
                                <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                    <IconButton
                                        size='small'
                                        sx={{
                                            borderRadius: '4px',
                                            m: '4px',
                                            p: '7px'
                                        }}
                                        onClick={handleAddPatientModalClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                            <AddPatientForm institution={taskinstitution} onSaveResult={handleOnSavePatientResultReturned} />
                        </Stack>
                    </Paper>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="notification-modal-title"
                aria-describedby="notification-modal-description"
                open={openNotificationModal}
                onClose={handleNotificationModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openNotificationModal}>
                    <Paper
                        square={true}
                        elevation={0}
                        sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '400px',
                            height: '312px',
                            padding: 0
                        }}
                    >
                        <Stack spacing={0}>
                            <Toolbar
                                variant='dense'
                                sx={{
                                    minHeight: '38px',
                                    borderBottom: '2px solid #212121'
                                }}
                            >
                                <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                    <Typography id="notification-modal-title" variant='subtitle1'>Notification</Typography>
                                </Box>
                                <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                    <IconButton
                                        size='small'
                                        sx={{
                                            borderRadius: '4px',
                                            m: '4px',
                                            p: '7px'
                                        }}
                                        onClick={handleNotificationModalClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: '200px',
                                    p: 1,
                                    overflow: 'auto'
                                }}
                            >
                                <Typography variant='body1'>{notificationmsg}</Typography>
                            </Box>
                            <Box
                                justifyContent='flex-end'
                                sx={{
                                    display: 'flex',
                                    minHeight: '38px',
                                    pt: 1,
                                    pl: 2,
                                    pr: 2,
                                    borderTop: '1px solid #212121'
                                }}
                            >
                                <Button
                                    variant='contained'
                                    onClick={handleNotificationModalClose}
                                >
                                    Close
                                </Button>
                            </Box>
                        </Stack>
                    </Paper>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="addphysician-modal-title"
                aria-describedby="addphysician-modal-description"
                open={openAddPhysicianModal}
                onClose={handleAddPhysicianModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openAddPhysicianModal}>
                    <Paper
                        square={true}
                        elevation={0}
                        sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '400px',
                            height: '450px',
                            padding: 0
                        }}
                    >
                        <Stack spacing={0}>
                            <Toolbar
                                variant='dense'
                                sx={{
                                    minHeight: '38px',
                                    borderBottom: '2px solid #212121'
                                }}
                            >
                                <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                    <Typography id="addphysician-modal-title" variant='subtitle1'>Add New Physician</Typography>
                                </Box>
                                <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                    <IconButton
                                        size='small'
                                        sx={{
                                            borderRadius: '4px',
                                            m: '4px',
                                            p: '7px'
                                        }}
                                        onClick={handleAddPhysicianModalClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                            <AddPhysicianForm onSaveResult={handleOnSavePhysicianResultReturned} />
                        </Stack>
                    </Paper>
                </Fade>
            </Modal>
        </>
    )
}