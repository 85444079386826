import { Avatar, Box, Button, ButtonGroup, Checkbox, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, Menu, MenuItem, MenuProps, Select, SelectChangeEvent, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material'
import * as React from 'react'
import { styled, alpha } from '@mui/material/styles';
import { GIOrgan, GIOrganLabel, GIOrganLabelViewset, ImageDataframe, ImageDataframeViewset, LesionLabelViewset, instanceofGIOrganLabel, instanceofImageDataframe } from '../../../../types'
import { ImageDataframeList } from '../lists/ImageDataframeList'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ClearIcon from '@mui/icons-material/Clear';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { LesionLabelImageView } from './LesionLabelImageView'
import { EditOrganLabel } from '../forms/EditOrganLabel'
import { Heatmap } from '../../charts/Heatmap'
import { deleteOrganLabelsAPI, fetchGIOrganLabelAPI, markorganforexportAPI, markorganlabelsAPI, unmarkorganforexportAPI } from '../../../../reducers/func/dataManagementFunc';
import { set } from 'lodash';

interface IGIOrganLabelView {
    gi: GIOrgan | null
}

const DropDownMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export const GIOrganLabelView: React.FC<IGIOrganLabelView> = (props: IGIOrganLabelView) => {
    // const [viewrange, setViewRange] = React.useState<number[]>([])
    const [viewlabel, setViewLabel] = React.useState<string>('')
    const [viewfirstlabel, setViewFirstLabel] = React.useState<string>('')
    const [multiSelection, setMultiSelection] = React.useState<boolean>(false)
    const [giorganlabelviewset, setGIOrganLabelViewset] = React.useState<GIOrganLabelViewset | null>(null)
    const [viewoption, setViewOption] = React.useState<string | null>('thumb')
    const [ddanchorEl, setDDAnchorEl] = React.useState<null | HTMLElement>(null);
    const [loading, setLoading] = React.useState<boolean>(false)
    const open = Boolean(ddanchorEl);
    const rows = 20
    const cols = 20
    const handleDDMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setDDAnchorEl(event.currentTarget);
    }
    const handleDDMenuClose = () => {
        setDDAnchorEl(null);
    }
    const { gi } = props

    const handleViewOptionChange = (event: React.MouseEvent<HTMLElement>,
        newOption: string | null) => {
        setGIOrganLabelViewset(null)
        setViewOption(newOption)
    }

    const handelViewLabelChange = (event: SelectChangeEvent) => {
        setViewLabel(event.target.value as string)
        setViewFirstLabel('')
        setGIOrganLabelViewset(null)
    }

    const handelViewFirstLabelChange = (event: React.MouseEvent<HTMLElement>,
        newOption: string | null) => {
        setViewFirstLabel(newOption as string)
        setViewLabel('')
        setGIOrganLabelViewset(null)
    }

    const handleFetchData = (page: number) => {
        if (gi) {
            setLoading(true)
            const num_items = viewoption === 'thumb' ? 12 : rows * cols
            fetchGIOrganLabelAPI(gi.id, viewlabel, viewfirstlabel, page, num_items)
                .then((response) => {
                    if (page > 1 && giorganlabelviewset !== null) {
                        setGIOrganLabelViewset({
                            ...giorganlabelviewset,
                            data: giorganlabelviewset?.data?.concat(response.data.results),
                            page: page,
                            count: response.data.count,
                        })
                    } else {
                        setGIOrganLabelViewset({
                            //...giorganlabelviewset,
                            data: response.data.results,
                            totalpages: response.data.total_pages,
                            page: 1,
                            count: response.data.count,
                            // selframe: response.data.results[0].dataframe,
                            selitem: response.data.results[0],
                            selitems: [],
                            pointer: 0
                        })
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    console.log("Error fetching image data: ", error)
                    setLoading(false)
                })
        }
    }



    const handleLoadMore = () => {
        if (giorganlabelviewset !== null) {
            if (giorganlabelviewset.page as number + 1 <= (giorganlabelviewset.totalpages as number)) {
                handleFetchData(giorganlabelviewset.page as number + 1)
            }
        }
    }

    // const handleRefreshData = () => {
    //     if (gi) {
    //         fetchGIOrganLabelAPI(gi.id, viewlabel, 1, 12)
    //             .then((response) => {

    //                 setGIOrganLabelViewset({
    //                     ...giorganlabelviewset,
    //                     data: response.data.results,
    //                     totalpages: response.data.total_pages,
    //                     count: response.data.count,
    //                 })
    //             })
    //             .catch((error) => {
    //                 console.log("Error refreshing data: ", error)
    //             })
    //     }
    // }

    // const handleDataListScrollEnd = (event: any) => {
    //     if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop == 420) {
    //         if (giorganlabelviewset?.page as number + 1 <= (giorganlabelviewset?.totalpages as number)) {
    //             handleFetchData(giorganlabelviewset?.page as number + 1)
    //         }
    //     }
    // }

    // const handleMarkUpdated = () => {

    //     handleRefreshData()
    // }

    // const handleFrameLabelEdited = (frame: ImageDataframe, label: number) => {
    //     // find label item
    //     const labelitem = giorganlabelviewset?.data?.find((element: GIOrganLabel) => element.dataframe?.id === frame.id)
    //     if (labelitem !== undefined && instanceofGIOrganLabel(labelitem)) {
    //         editLabelAPI(labelitem.id as string, label)
    //             .then((response) => {
    //                 handleRefreshData()
    //             })
    //             .catch((error) => {
    //                 console.log("Error updating label: ", error)
    //             })
    //     }
    // }

    const handleFrameSelected = (item: ImageDataframe) => {
        // check type of item
        if (item !== undefined && instanceofImageDataframe(item)) {
            // find pointer location
            if (giorganlabelviewset !== null) {
                const pointer = giorganlabelviewset.data?.findIndex((element: GIOrganLabel) => element.dataframe?.id === item.id)
                const selitems = giorganlabelviewset.selitems
                if (pointer !== undefined && pointer >= 0) {
                    if (multiSelection) {
                        if (giorganlabelviewset.selitems !== null) {
                            const gi_index = giorganlabelviewset.selitems?.findIndex((element: GIOrganLabel) => element.dataframe?.id === item.id)
                            if (gi_index === -1) {
                                // get item at gi_index
                                const gi_item = giorganlabelviewset.data?.find((element: GIOrganLabel) => element.dataframe?.id === item.id)
                                selitems?.push(gi_item as GIOrganLabel)
                            } else if (gi_index !== undefined) {
                                selitems?.splice(gi_index, 1)
                            }

                        }
                    }

                    setGIOrganLabelViewset({
                        ...giorganlabelviewset,
                        selitem: giorganlabelviewset.data?.[pointer as number] as GIOrganLabel,
                        selitems: selitems,
                        pointer: pointer
                    })
                }
            }

        }
    }

    const handleViewsetChanged = (viewset: ImageDataframeViewset | GIOrganLabelViewset | LesionLabelViewset) => {
        console.log("Viewset changed: ", giorganlabelviewset)
        setGIOrganLabelViewset(viewset as GIOrganLabelViewset)
    }

    const nextFrame = () => {
        const pointer = giorganlabelviewset?.pointer as number
        if (pointer + 1 < (giorganlabelviewset?.data?.length as number)) {
            const nextframe = giorganlabelviewset?.data?.[pointer + 1]
            handleFrameSelected(nextframe?.dataframe as ImageDataframe)
        }
    }

    const prevFrame = () => {
        const pointer = giorganlabelviewset?.pointer as number
        if (pointer - 1 >= 0) {
            const prevframe = giorganlabelviewset?.data?.[pointer - 1]
            handleFrameSelected(prevframe?.dataframe as ImageDataframe)
        }
    }

    const handleOrganLabelEdited = (organlabel: GIOrganLabel) => {
        if (giorganlabelviewset !== null) {
            const newdata = giorganlabelviewset.data?.map((element: GIOrganLabel) => {
                if (element.id === organlabel.id) {
                    return organlabel
                } else {
                    return element
                }
            })
            setGIOrganLabelViewset({
                ...giorganlabelviewset,
                data: newdata,
                selitem: organlabel
            })
        }
    }

    const toggleMultiSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMultiSelection(event.target.checked);
        // Add current item to selection list
        if (giorganlabelviewset !== null) {
            if (event.target.checked) {
                const selitems: GIOrganLabel[] = []
                selitems.push(giorganlabelviewset.selitem as GIOrganLabel)
                setGIOrganLabelViewset({
                    ...giorganlabelviewset,
                    selitems: selitems
                })
            } else {
                setGIOrganLabelViewset({
                    ...giorganlabelviewset,
                    selitems: []
                })
            }
        }
    }

    const markSelectionsForExport = () => {
        if (giorganlabelviewset !== null) {
            const selitems = giorganlabelviewset.selitems
            if (selitems !== null && selitems !== undefined) {
                // initialize export list
                const exportlist: number[] = []
                selitems.forEach((element: GIOrganLabel) => {
                    // add item id to export list
                    exportlist.push(element.id as unknown as number)
                })
                // call mark for export API
                markorganforexportAPI(gi?.id as string, exportlist)
                    .then((response) => {
                        // refresh data
                        // setGIOrganLabelViewset(null)
                        handleFetchData(giorganlabelviewset.page as number)
                    })
                    .catch((error) => {
                        console.log("Error marking for export: ", error)
                    })
            }
        }
    }

    const unmarkSelectionsForExport = () => {
        if (giorganlabelviewset !== null) {
            const selitems = giorganlabelviewset.selitems
            if (selitems !== null && selitems !== undefined) {
                // initialize export list
                const exportlist: number[] = []
                selitems.forEach((element: GIOrganLabel) => {
                    // add item id to export list
                    exportlist.push(element.id as unknown as number)
                })
                // call mark for export API
                unmarkorganforexportAPI(gi?.id as string, exportlist)
                    .then((response) => {
                        // refresh data
                        // setGIOrganLabelViewset(null)
                        handleFetchData(giorganlabelviewset.page as number)
                    })
                    .catch((error) => {
                        console.log("Error marking for export: ", error)
                    })
            }
        }
    }

    const markOrganLabels = (label: number) => {
        if (giorganlabelviewset !== null) {
            const selitems = giorganlabelviewset.selitems
            if (selitems !== null && selitems !== undefined) {
                // initialize labels list
                const labelslist: number[] = []
                selitems.forEach((element: GIOrganLabel) => {
                    // add item id to labels list
                    labelslist.push(element.id as unknown as number)
                })
                // call mark for export API
                markorganlabelsAPI(gi?.id as string, labelslist, label)
                    .then((response) => {
                        // refresh data
                        // setGIOrganLabelViewset(null)
                        handleFetchData(giorganlabelviewset.page as number)
                    })
                    .catch((error) => {
                        console.log("Error marking labels: ", error)
                    })
            }
        }
    }

    const deleteSelectedItems = () => {
        if (giorganlabelviewset !== null) {
            const selitems = giorganlabelviewset.selitems
            if (selitems !== null && selitems !== undefined) {
                // initialize labels list
                const labelslist: number[] = []
                selitems.forEach((element: GIOrganLabel) => {
                    // add item id to labels list
                    labelslist.push(element.id as unknown as number)
                }
                )
                // call mark for export API
                deleteOrganLabelsAPI(labelslist)
                    .then((response) => {
                        // refresh data
                        handleFetchData(giorganlabelviewset.page as number)
                    })
                    .catch((error) => {
                        console.log("Error marking labels: ", error)
                    })
            }
        }
    }

    // React.useEffect(() => {
    //     if (gi && gi.dataset && viewrange.length <= 0) {
    //         setViewRange([gi.dataset.start_ts, gi.dataset.stop_ts])
    //     }
    // }, [giorganlabelviewset])

    React.useEffect(() => {
        handleFetchData(1)
    }, [viewlabel, viewfirstlabel])

    React.useEffect(() => {
        if (giorganlabelviewset == null) {
            handleFetchData(1)
        }
    }, [giorganlabelviewset])




    return (
        <>
            <Stack direction='row' spacing={0}>
                <Box
                    sx={{
                        width: '50%',
                        height: 500,
                    }}
                >
                    <Box
                        sx={{
                            width: 500,

                        }}>
                        <Grid container direction='row' spacing={1}>
                            <Grid item xs={4} alignContent='center' alignItems='flex-start' textAlign='start'>
                                <FormControl
                                    variant='standard'
                                    sx={{ minWidth: 120, pb: 1 }}>
                                    <InputLabel id="viewlabel-select-label">Label</InputLabel>
                                    <Select
                                        labelId="viewlabel-select-label"
                                        id="viewlabel-select"
                                        value={viewlabel}
                                        label="Label"
                                        onChange={handelViewLabelChange}>
                                        <MenuItem value={''}>All</MenuItem>
                                        <MenuItem value={-1}>N/A</MenuItem>
                                        <MenuItem value={0}>Esophagus</MenuItem>
                                        <MenuItem value={1}>Stomach</MenuItem>
                                        <MenuItem value={2}>Small Bowel</MenuItem>
                                        <MenuItem value={3}>Colon</MenuItem>
                                    </Select>

                                </FormControl>
                            </Grid>
                            <Grid item xs={6} alignContent='center' alignItems='center'>
                                <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
                                    <ToggleButtonGroup
                                        value={viewfirstlabel}
                                        exclusive
                                        onChange={handelViewFirstLabelChange}
                                        size='small'


                                    >
                                        <ToggleButton value='esophagus' aria-label='esophagus'>
                                            <Avatar sx={{ width: 24, height: 24, fontSize: 11, bgcolor: 'black' }}>ES</Avatar>
                                        </ToggleButton>
                                        <ToggleButton value='stomach' aria-label='stomach'>
                                            <Avatar color='primary' sx={{ width: 24, height: 24, fontSize: 11, bgcolor: 'black' }}>ST</Avatar>
                                        </ToggleButton>
                                        <ToggleButton value='smallbowel' aria-label='smallbowel'>
                                            <Avatar color='primary' sx={{ width: 24, height: 24, fontSize: 11, bgcolor: 'black' }}>SB</Avatar>
                                        </ToggleButton>
                                        <ToggleButton value='colon' aria-label='colon'>
                                            <Avatar color='primary' sx={{ width: 24, height: 24, fontSize: 11, bgcolor: 'black' }}>CO</Avatar>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>
                            </Grid>
                            <Grid item xs={2} alignContent='center' alignItems='flex-end' textAlign='end'>
                                <ToggleButtonGroup
                                    value={viewoption}
                                    exclusive
                                    onChange={handleViewOptionChange}
                                    size='small'
                                    color='primary'

                                >
                                    <ToggleButton value='thumb' aria-label='thumb'>
                                        <ViewModuleIcon />
                                    </ToggleButton>
                                    <ToggleButton value='heatmap' aria-label='heatmap'>
                                        <ViewCompactIcon />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>

                        {viewoption === 'thumb' ? (


                            <ImageDataframeList
                                datatype='organ'
                                // dataid={gi?.id as string}
                                viewset={giorganlabelviewset as GIOrganLabelViewset}
                                // start={gi?.dataset?.start_ts as number}
                                // stop={gi?.dataset?.stop_ts as number}
                                showLabel={true}
                                // multiSelection={multiSelection}
                                // onViewsetUpdated={handleViewsetChanged}
                                onItemSelected={handleFrameSelected}
                            />


                        ) : (

                            <Heatmap
                                datatype='organ'
                                // dataid={gi?.id as string}
                                viewset={giorganlabelviewset as GIOrganLabelViewset}
                                width={500}
                                height={480}
                                rows={rows}
                                cols={cols}
                                margin={
                                    {
                                        top: 10,
                                        right: 5,
                                        bottom: 20,
                                        left: 5
                                    }
                                }
                                onViewsetUpdated={handleViewsetChanged}
                                onItemSelected={handleFrameSelected}
                            />

                        )}
                    </Box>
                    <Box>
                        <Grid container>
                            <Grid item xs={9} alignItems='flex-start'>
                                <Stack direction='row' spacing={1} alignItems='left' justifyContent='left'>

                                    <FormControlLabel
                                        label={multiSelection ? giorganlabelviewset?.selitems?.length + ' selected' : 'Enable Multi-Select'}
                                        control={<Checkbox checked={multiSelection} onChange={toggleMultiSelection} />}
                                    />

                                    <div>
                                        <Button
                                            id="demo-customized-button"
                                            aria-controls={open ? 'demo-customized-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            variant="contained"
                                            disableElevation
                                            onClick={handleDDMenuClick}
                                            endIcon={<KeyboardArrowDownIcon />}
                                        >
                                            Mark as
                                        </Button>
                                        <DropDownMenu
                                            id="demo-customized-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'demo-customized-button',
                                            }}
                                            anchorEl={ddanchorEl}
                                            open={open}
                                            onClose={handleDDMenuClose}
                                        >
                                            <MenuItem onClick={() => { handleDDMenuClose(); markOrganLabels(-1); }} disableRipple>
                                                N/A
                                            </MenuItem>
                                            <MenuItem onClick={() => { handleDDMenuClose(); markOrganLabels(0); }} disableRipple>
                                                Esophagus
                                            </MenuItem>
                                            <MenuItem onClick={() => { handleDDMenuClose(); markOrganLabels(1); }} disableRipple>
                                                Stomach
                                            </MenuItem>
                                            <MenuItem onClick={() => { handleDDMenuClose(); markOrganLabels(2); }} disableRipple>
                                                Small Bowel
                                            </MenuItem>
                                            <MenuItem onClick={() => { handleDDMenuClose(); markOrganLabels(3); }} disableRipple>
                                                Colon
                                            </MenuItem>
                                        </DropDownMenu>
                                    </div>
                                    <ButtonGroup
                                        aria-label='tool-buttons'
                                        disabled={giorganlabelviewset?.selitems === null || giorganlabelviewset?.selitems === undefined || giorganlabelviewset?.selitems?.length === 0}
                                    >
                                        <IconButton aria-label="delete"
                                            onClick={deleteSelectedItems}
                                        >
                                            <Tooltip title="Delete">
                                                <DeleteIcon />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton aria-label="add-in-report"
                                            onClick={markSelectionsForExport}
                                        >
                                            <Tooltip title="Add to report">
                                                <NoteAddIcon />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton aria-label="remove-from-report"
                                            onClick={unmarkSelectionsForExport}
                                        >
                                            <Tooltip title="Remove from report">
                                                <ClearIcon />
                                            </Tooltip>
                                        </IconButton>

                                    </ButtonGroup>
                                </Stack>
                            </Grid>
                            <Grid item xs={3} alignItems='flex-end'>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    size='small'
                                    endIcon={loading ? <CircularProgress size={16} /> : <MoreHorizIcon />}
                                    onClick={handleLoadMore}
                                    disabled={loading}
                                    sx={{
                                        width: 120,
                                        mr: 2,
                                        pr: 0
                                    }}>
                                    {loading ? 'Loading' : 'Load more'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '50%',
                        ml: 4
                    }}
                >
                    <Grid container direction='column' spacing={1} alignItems='center' justifyContent='center'>
                        <Grid item xs={12}>
                            <LesionLabelImageView
                                image={giorganlabelviewset?.selitem?.dataframe?.imagedata}
                                canvaswidth={500}
                                canvasheight={320} />
                            {/* <Stage width={500} height={320}>
                                <Layer>
                                    <Image
                                        image={selFrameImage}
                                        x={imageStartPos[0]}
                                        y={imageStartPos[1]}
                                        scaleX={zoomLevel}
                                        scaleY={zoomLevel}
                                        ref={imageRef}
                                        draggable={true}
                                        onDragStart={dragStart}
                                        onDragEnd={dragEnd}
                                    />
                                </Layer>

                            </Stage>
                            <CustomFab
                                aria-label="zoomin"
                                onClick={() => { zoomIn() }}
                                disabled={zoomLevel < 2 ? false : true}
                                sx={{
                                    position: 'absolute' as 'absolute',
                                    top: 96
                                }}>
                                <ZoomInIcon fontSize="large" />
                            </CustomFab>
                            <CustomFab
                                aria-label="zoomout"
                                onClick={() => { zoomOut() }}
                                disabled={zoomLevel > 1 ? false : true}
                                sx={{
                                    position: 'absolute' as 'absolute',
                                    top: 144
                                }}>
                                <ZoomOutIcon fontSize="large" />
                            </CustomFab>
                            <CustomFab
                                aria-label="reset"
                                onClick={resetImageView}
                                sx={{
                                    position: 'absolute' as 'absolute',
                                    top: 192
                                }}>
                                <RestartAltIcon fontSize="large" />
                            </CustomFab> */}

                        </Grid>
                        <Grid item xs={12}>
                            <IconButton edge='end' aria-label='First frame'>
                                <SkipPreviousIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            <IconButton edge='end' aria-label='Previous frame' onClick={prevFrame}>
                                <NavigateBeforeIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            <IconButton edge='end' aria-label='Next frame' onClick={nextFrame}>
                                <NavigateNextIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            <IconButton edge='end' aria-label='Last frame'>
                                <SkipNextIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} alignItems='flex-start' justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <EditOrganLabel organlabel={giorganlabelviewset?.selitem as GIOrganLabel} onEdit={handleOrganLabelEdited} />
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </>
    )
}