/* eslint-disable jsx-a11y/alt-text */
import { Avatar, Backdrop, Box, Button, ButtonGroup, Checkbox, CircularProgress, Fade, FormControlLabel, FormLabel, Grid, IconButton, Modal, Paper, Slider, Stack, ToggleButton, Toolbar, Tooltip, Typography } from '@mui/material'
import * as React from 'react'
import { Dataset, GIOrganLabelViewset, ImageDataframe, ImageDataframeViewset, LesionLabelViewset, instanceofImageDataframe } from '../../../../types'
import { ImageDataframeList } from '../lists/ImageDataframeList'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';

import { LesionLabelImageView } from './LesionLabelImageView'
import { EditImageDataframe } from '../forms/EditImageDataframe';
import { set } from 'lodash';
import { deleteImageframesAPI, fetchDatasetAPI, fetchImageDataByTSAPI, importTSdataAPI } from '../../../../reducers/func/dataManagementFunc';
import { useAppSelector } from '../../../../hooks/AppReducerHooks';

interface IDatasetView {
    ds: Dataset | null
    // onUpdate: (ds: string) => void
}

function viewrangeHMS(value: number) {
    const h = Math.floor(value / (1000 * 60 * 60))
    const m = Math.floor((value / (1000 * 60)) % 60)
    const s = Math.floor((value / 1000) % 60)
    // format to HH:MM:SS
    return `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`
}

export const DatasetView: React.FC<IDatasetView> = (props: IDatasetView) => {
    const userInfo = useAppSelector((state) => state.user)
    const [viewrange, setViewRange] = React.useState<number[]>([])
    const [start, setStart] = React.useState<number>(0)
    const [stop, setStop] = React.useState<number>(0)
    const [multiSelection, setMultiSelection] = React.useState<boolean>(false)
    const [imagedataviewset, setImageDataViewset] = React.useState<ImageDataframeViewset | null>(null)
    const [importing, setImporting] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [openReloadDSModal, setOpenReloadDSModal] = React.useState(false)
    const [openNotificationModal, setOpenNotificationModal] = React.useState(false)
    const [notificationmsg, setNotificationMsg] = React.useState('')
    const tsfileRef = React.useRef<any>(null)
    // const [selitem, setSelItem] = React.useState<any>(null)
    //const [selFrameImage, setSelFrameImage] = React.useState<any>(null)
    const { ds } = props
    const notification = new WebSocket(
        `wss://staging-events.gutdx.com/notifications/${userInfo?.profile?.uid}/?token=${userInfo?.accessToken}`
    )
    notification.onmessage = (event) => {
        console.log("Notification: ", event.data)
        // parse notification data
        const data = JSON.parse(event.data)
        if (data.message === "import_timestamp_data_event" && importing) {
            fetchDatasetAPI(ds?.id as string)
                .then((response) => {
                    const ds_data = response.data
                    if (ds_data.importing_ts === 1) {
                        setImporting(true)
                    } else if (ds_data.importing_ts === 2) {
                        setImporting(false)
                        setOpenReloadDSModal(true)
                    } else {
                        setImporting(false)
                    }
                })
                .catch((error) => {
                    console.log("Error fetching dataset: ", error)
                })
        }

        if (data.message === "import_timestamp_data_event_error" && importing) {
            setImporting(false)
            setNotificationMsg("Error importing timestamp data. Please review the worker log.")
            setOpenNotificationModal(true)
        }

    }

    // const canvaswidth = 500
    // const canvasheight = 320
    // const imageRef = React.createRef<any>()
    // const [zoomLevel, setZoomLevel] = React.useState<number>(1)
    // const [imageStartPos, setImageStartPos] = React.useState<number[]>([0, 0])
    // const [isDragging, setIsDragging] = React.useState<boolean>(false)

    const handleViewRangeChange = (event: Event, newValue: number | number[]) => {
        const range = newValue as number[]
        setViewRange(range);
    }

    // const handleFetchData = (page: number) => {
    //     if (ds) {
    //         //fetchImageDataAPI(ds.id, 10 ** (viewrange.at(0) as number), 10 ** (viewrange.at(1) as number), page)
    //         fetchImageDataByTSAPI(ds.id, (viewrange.at(0) as number), (viewrange.at(1) as number), page)
    //             .then((response) => {
    //                 if (imagedataviewset !== null && page > 1) {
    //                     // handle scroll to next page
    //                     setImageDataViewset({
    //                         ...imagedataviewset,
    //                         data: imagedataviewset?.data?.concat(response.data.results),
    //                         page: page,
    //                         count: response.data.count,
    //                     })
    //                 } else {
    //                     // handle load first page when viewrange is changed or on the first load
    //                     let frameimage = new window.Image()
    //                     frameimage.src = response.data.results[0].imagedata
    //                     frameimage.crossOrigin = 'anonymous'
    //                     frameimage.onload = () => {
    //                         setSelFrameImage(frameimage)
    //                         setZoomLevel(1)
    //                         const x = (canvaswidth - frameimage.width) / 2
    //                         const y = (canvasheight - frameimage.height) / 2
    //                         setImageStartPos([x, y])
    //                     }
    //                     // setSelFrame(response.data.results[0])
    //                     setImageDataViewset({
    //                         ...imagedataviewset,
    //                         data: response.data.results,
    //                         totalpages: response.data.total_pages,
    //                         page: 1,
    //                         count: response.data.count,
    //                         selframe: response.data.results[0],
    //                         pointer: 0
    //                     })
    //                 }

    //             })
    //             .catch((error) => {
    //                 console.log("Error fetching image data: ", error)
    //             })
    //     }
    // }

    // const handleRefreshData = () => {
    //     if (ds) {
    //         //fetchImageDataAPI(ds.id, 10 ** (viewrange.at(0) as number), 10 ** (viewrange.at(1) as number), imagedataviewset?.page as number)
    //         fetchImageDataByTSAPI(ds.id, (viewrange.at(0) as number), (viewrange.at(1) as number), imagedataviewset?.page as number)
    //             .then((response) => {

    //                 setImageDataViewset({
    //                     ...imagedataviewset,
    //                     data: response.data.results,
    //                     totalpages: response.data.total_pages,
    //                     count: response.data.count,
    //                 })


    //             })
    //             .catch((error) => {
    //                 console.log("Error refreshing image data: ", error)
    //             })
    //     }
    // }

    // const handleDataListScrollEnd = (event: any) => {
    //     if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop === 420) {
    //         if (imagedataviewset?.page as number + 1 <= (imagedataviewset?.totalpages as number)) {
    //             handleFetchData(imagedataviewset?.page as number + 1)
    //         }
    //     }
    // }

    // const handleMarkUpdated = () => {
    //     handleRefreshData()
    //     onUpdate(ds?.id as string)
    // }

    const handleFrameSelected = (item: ImageDataframe) => {
        if (item !== undefined && instanceofImageDataframe(item)) {
            // find pointer location
            if (imagedataviewset !== null) {
                const pointer = imagedataviewset.data?.findIndex((element: ImageDataframe) => element.id === item.id)
                const selitems = imagedataviewset.selitems
                if (multiSelection) {
                    if (imagedataviewset.selitems !== null) {
                        const index = selitems?.findIndex((element: ImageDataframe) => element.id === item.id)
                        if (index === -1) {
                            selitems?.push(item)
                        } else if (index !== undefined) {
                            selitems?.splice(index, 1)
                        }

                    }
                }
                setImageDataViewset({
                    ...imagedataviewset,
                    selitem: item,
                    selitems: selitems,
                    pointer: pointer
                })
                // setSelItem(item)
                //setSelFrameImage(item.imagedata)
            }
        }
        // setSelFrame(item)

    }

    const nextFrame = () => {
        const pointer = imagedataviewset?.pointer as number
        if (pointer + 1 < (imagedataviewset?.data?.length as number)) {
            const nextframe = imagedataviewset?.data?.[pointer + 1]
            handleFrameSelected(nextframe as ImageDataframe)
        }
    }

    const prevFrame = () => {
        const pointer = imagedataviewset?.pointer as number
        if (pointer - 1 >= 0) {
            const prevframe = imagedataviewset?.data?.[pointer - 1]
            handleFrameSelected(prevframe as ImageDataframe)
        }
    }

    const handleViewsetChanged = (viewset: ImageDataframeViewset) => {
        console.log("Viewset changed: ", imagedataviewset)
        setImageDataViewset(viewset as ImageDataframeViewset)
    }

    const toggleMultiSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMultiSelection(event.target.checked);
        // Add current selitem to selitems
        if (imagedataviewset !== null) {
            if (event.target.checked) {
                setImageDataViewset({
                    ...imagedataviewset,
                    selitems: [imagedataviewset.selitem as ImageDataframe]
                })
            } else {
                setImageDataViewset({
                    ...imagedataviewset,
                    selitems: []
                })
            }
        }
    }

    const handleDataframeEdited = (dataframe: ImageDataframe) => {
        if (imagedataviewset !== null) {
            const data = imagedataviewset.data?.map((element: ImageDataframe) => {
                if (element.id === dataframe.id) {
                    return dataframe
                } else {
                    return element
                }
            })
            setImageDataViewset({
                ...imagedataviewset,
                selitem: dataframe,
                data: data
            })
            // setSelItem(dataframe)
        }
    }

    const handleOpenFileBrowser = () => {
        if (tsfileRef.current !== null) {
            tsfileRef.current.click()
        }
    }

    const handleSelectTSFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation()
        event.preventDefault()
        onTSFileSelected(event.target.files as FileList)
    }

    const onTSFileSelected = (files: FileList) => {
        if (files.length > 0) {
            const file = files[0]
            importTSdataAPI(ds?.id as string, file)
                .then((response) => {
                    console.log("File importing in progress: ", response)
                    setImporting(true)
                })
                .catch((error) => {
                    console.log("Error importing file: ", error)
                    setImporting(false)
                })
        }
    }

    const handleLoadMore = () => {
        if (imagedataviewset !== null) {
            if (imagedataviewset.page as number + 1 <= (imagedataviewset.totalpages as number)) {
                handleFetchData(imagedataviewset.page as number + 1)
            }
        }
    }

    const handleFetchData = (page: number) => {
        setLoading(true)
        fetchImageDataByTSAPI(ds?.id as string, (viewrange.at(0) as number), (viewrange.at(1) as number), page)
            .then((response) => {
                if (page > 1 && imagedataviewset !== null) {
                    const data = imagedataviewset?.data?.concat(response.data.results) as any
                    handleViewsetChanged({
                        ...imagedataviewset,
                        data: data,
                        page: page,
                        count: response.data.count,
                    })
                } else {
                    handleViewsetChanged({
                        data: response.data.results,
                        totalpages: response.data.total_pages,
                        page: 1,
                        count: response.data.count,
                        selitem: response.data.results[0],
                        selitems: [],
                        pointer: 0
                    })
                }
                setLoading(false)
            })
            .catch((error) => {
                console.log("Error fetching dataframes: ", error)
                setLoading(false)
            })
    }

    const handleDeletedSelectedItems = () => {
        if (imagedataviewset !== null) {
            const selitems = imagedataviewset.selitems
            if (selitems !== null && selitems !== undefined) {
                // initialize labels list
                const labelslist: number[] = []
                selitems.forEach((element: ImageDataframe) => {
                    // add item id to labels list
                    labelslist.push(element.id as unknown as number)
                }
                )
                // delete selected items
                deleteImageframesAPI(labelslist)
                    .then((response) => {
                        console.log("Deleted items: ", response)
                        handleFetchData(imagedataviewset.page as number)
                    })
                    .catch((error) => {
                        console.log("Error deleting items: ", error)
                    })
            }
        }
    }

    const handleReloadDSModalClose = () => {
        setOpenReloadDSModal(false)
    }

    const handleReloadDS = () => {
        setOpenReloadDSModal(false)
        handleFetchData(1)
    }

    const handleNotificationModalClose = () => setOpenNotificationModal(false)

    React.useEffect(() => {
        if (ds !== null && ds !== undefined) {
            if (ds.importing_ts === 1) {
                setImporting(true)
            } else {
                setImporting(false)
            }
            setStart(ds.start_ts as number)
            setStop(ds.stop_ts as number)
            setViewRange([ds.start_ts as number, ds.stop_ts as number])
        }
    }, [ds])
    React.useEffect(() => {
        handleFetchData(1)
    }, [viewrange])

    return (
        <>
            <Stack direction='row' spacing={0}>
                <Box
                    sx={{
                        width: '50%',
                        height: 520,
                    }}
                >
                    <Box
                        sx={{
                            width: 500,

                        }}>

                        <Typography id="timeframe-slider" gutterBottom>
                            Search data by timeframe
                        </Typography>
                        <Slider
                            getAriaLabel={() => 'Dataframes view range'}
                            value={viewrange}
                            onChange={handleViewRangeChange}
                            valueLabelDisplay="off"
                            getAriaValueText={viewrangeHMS}
                            step={1}
                            min={start}
                            max={stop}
                            marks={
                                [
                                    {
                                        value: start,
                                        label: `${viewrangeHMS(start)}`
                                    },
                                    {
                                        value: Math.ceil((stop - start) / 4),
                                        label: `${viewrangeHMS(Math.ceil((stop - start) / 4))}`
                                    },
                                    {
                                        value: Math.ceil((stop - start) / 2),
                                        label: `${viewrangeHMS(Math.ceil((stop - start) / 2))}`
                                    },
                                    {
                                        value: Math.ceil((stop - start) * 3 / 4),
                                        label: `${viewrangeHMS(Math.ceil((stop - start) * 3 / 4))}`
                                    },
                                    {
                                        value: stop,
                                        label: `${viewrangeHMS(stop)}`
                                    }
                                ]}

                        />

                        <ImageDataframeList
                            datatype='dataframe'
                            // dataid={ds?.id as string}
                            viewset={imagedataviewset as ImageDataframeViewset}
                            // start={ds?.start_ts as number}
                            // stop={ds?.stop_ts as number}
                            showLabel={true}
                            // multiSelection={multiSelection}
                            // onViewsetUpdated={handleViewsetChanged}
                            onItemSelected={handleFrameSelected}
                        />
                    </Box>
                    <Box>

                        <Grid container>
                            <Grid item xs={3} alignContent='center'>
                                <Stack direction='row' spacing={1} alignItems='left' justifyContent='left'>
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        size='small'
                                        sx={{
                                            marginRight: 2
                                        }}
                                        onClick={handleOpenFileBrowser}
                                        disabled={importing}>
                                        {importing ? 'Importing...' : 'Import time data'}
                                        <input type="file" id="tsfile"
                                            ref={tsfileRef}
                                            style={{ display: 'none' }}
                                            accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            onChange={handleSelectTSFile} />
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} alignContent='center'>
                                <Stack direction='row' spacing={1} alignItems='right' justifyContent='right'>
                                    <FormControlLabel
                                        label={multiSelection ? imagedataviewset?.selitems?.length + ' selected' : 'Enable Multi-Select'}
                                        control={<Checkbox checked={multiSelection} onChange={toggleMultiSelection} />}
                                    />



                                    <IconButton
                                        aria-label="delete"
                                        onClick={handleDeletedSelectedItems}
                                        disabled={imagedataviewset?.selitem === null || imagedataviewset?.selitem === undefined}>
                                        <Tooltip title='Delete selected item'>
                                            <DeleteIcon />
                                        </Tooltip>
                                    </IconButton>
                                </Stack>
                            </Grid>
                            <Grid item xs={3} alignContent='center' alignItems='flex-end'>

                                <Button
                                    variant='outlined'
                                    color='primary'
                                    size='small'
                                    endIcon={loading ? <CircularProgress size={16} /> : <MoreHorizIcon />}
                                    onClick={handleLoadMore}
                                    disabled={loading}
                                    sx={{
                                        width: 120,
                                        mr: 2,
                                        pr: 0
                                    }}>
                                    {loading ? 'Loading' : 'Load more'}
                                </Button>

                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '50%',
                        ml: 4
                    }}
                >
                    <Grid container direction='column' spacing={1} alignItems='center' justifyContent='center'>
                        <Grid item xs={12}>
                            <LesionLabelImageView
                                image={imagedataviewset?.selitem?.imagedata as string}
                                canvaswidth={500}
                                canvasheight={320} />
                        </Grid>
                        <Grid item xs={12}>
                            <IconButton edge='end' aria-label='First frame'>
                                <SkipPreviousIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            <IconButton edge='end' aria-label='Previous frame' onClick={prevFrame}>
                                <NavigateBeforeIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            <IconButton edge='end' aria-label='Next frame' onClick={nextFrame}>
                                <NavigateNextIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            <IconButton edge='end' aria-label='Last frame'>
                                <SkipNextIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems='flex-start' justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <EditImageDataframe dataframe={imagedataviewset?.selitem as ImageDataframe} onEdit={handleDataframeEdited} />
                        </Grid>
                    </Grid>
                </Box>
            </Stack >
            <Modal
                aria-labelledby="reload-ds-modal-title"
                aria-describedby="reload-ds-modal-description"
                open={openReloadDSModal}
                onClose={handleReloadDSModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openReloadDSModal}>
                    <Paper
                        square={true}
                        elevation={0}
                        sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '400px',
                            height: '312px',
                            padding: 0
                        }}
                    >
                        <Stack spacing={0}>
                            <Toolbar
                                variant='dense'
                                sx={{
                                    minHeight: '38px',
                                    borderBottom: '2px solid #212121'
                                }}
                            >
                                <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                    <Typography id="reload-ds-modal-title" variant='subtitle1'>Reload Dataset</Typography>
                                </Box>
                                <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                    <IconButton
                                        size='small'
                                        sx={{
                                            borderRadius: '4px',
                                            m: '4px',
                                            p: '7px'
                                        }}
                                        onClick={handleReloadDSModalClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: '200px',
                                    p: 1,
                                    overflow: 'auto'
                                }}
                            >
                                <Typography variant='body1'>Dataset have been updated. Do you want to reload the dataset?</Typography>
                            </Box>
                            <Box
                                justifyContent='flex-end'
                                sx={{
                                    display: 'flex',
                                    minHeight: '38px',
                                    pt: 1,
                                    pl: 2,
                                    pr: 2,
                                    borderTop: '1px solid #212121'
                                }}
                            >
                                <Button
                                    variant='contained'
                                    onClick={handleReloadDSModalClose}
                                    sx={{
                                        ml: 1,
                                        mr: 1
                                    }}
                                >
                                    No
                                </Button>
                                <Button
                                    variant='contained'
                                    onClick={handleReloadDS}
                                    sx={{
                                        ml: 1,
                                        mr: 1
                                    }}
                                >
                                    Yes
                                </Button>
                            </Box>
                        </Stack>
                    </Paper>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="notification-modal-title"
                aria-describedby="notification-modal-description"
                open={openNotificationModal}
                onClose={handleNotificationModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openNotificationModal}>
                    <Paper
                        square={true}
                        elevation={0}
                        sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '400px',
                            height: '312px',
                            padding: 0
                        }}
                    >
                        <Stack spacing={0}>
                            <Toolbar
                                variant='dense'
                                sx={{
                                    minHeight: '38px',
                                    borderBottom: '2px solid #212121'
                                }}
                            >
                                <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                    <Typography id="addpatient-modal-title" variant='subtitle1'>Add New Patient</Typography>
                                </Box>
                                <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                    <IconButton
                                        size='small'
                                        sx={{
                                            borderRadius: '4px',
                                            m: '4px',
                                            p: '7px'
                                        }}
                                        onClick={handleNotificationModalClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: '200px',
                                    p: 1,
                                    overflow: 'auto'
                                }}
                            >
                                <Typography variant='body1'>{notificationmsg}</Typography>
                            </Box>
                            <Box
                                justifyContent='flex-end'
                                sx={{
                                    display: 'flex',
                                    minHeight: '38px',
                                    pt: 1,
                                    pl: 2,
                                    pr: 2,
                                    borderTop: '1px solid #212121'
                                }}
                            >
                                <Button
                                    variant='contained'
                                    onClick={handleNotificationModalClose}
                                >
                                    Close
                                </Button>
                            </Box>
                        </Stack>
                    </Paper>
                </Fade>
            </Modal>
        </>
    )
}